import API from "./axios";

import { ISurvey } from "../interfaces/survey";

class SurveyService {
  get = () => {
    return API.get("/api/Surveys")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  getById = (id: number) => {
    return API.get(`/api/Surveys/${id}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  add = (formData: ISurvey) => {
    return API.post("/api/Surveys", formData)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  update = (id: number, formData: ISurvey) => {
    return API.put(`/api/Surveys/${id}`, formData)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  duplicate = (id: number) => {
    return API.post(`/api/Surveys/${id}/clone`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  delete = (id: number) => {
    return API.put(`/api/Surveys/${id}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };
}

export default new SurveyService();
