import { createContext, SetStateAction, useState, Dispatch } from "react";
import { IUsage } from "../interfaces/report";

interface IUsageContext {
  usage: IUsage | undefined;
  setUsage: Dispatch<SetStateAction<IUsage>> | Dispatch<SetStateAction<undefined>>;
}

export const UsageContext = createContext<IUsageContext>({
  usage: undefined,
  setUsage: () => {},
});
