import { getFromStorage } from "../utils/utility";
import { DEFAULT_USER_AUTH_KEY } from "../utils/constants";
import { ILoginResponseValues } from "../interfaces/auth";

const useAuth = () => {
  const user = getFromStorage(DEFAULT_USER_AUTH_KEY);
  if (user) {
    const auth: ILoginResponseValues = JSON.parse(user);
    if (auth.token) {
      return auth;
    }
  }
  return null;
};

export default useAuth;
