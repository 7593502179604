import { FC, useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { Alert, Button, Divider, Form, Input, Typography } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

import SelectLanguage from "../../components/SelectLanguage";

import useAuth from "../../hooks/useAuth";
import { URL } from "../../utils/constants";
import { setUser } from "../../utils/utility";
import { ILocation } from "../../interfaces/common";
import AuthService from "../../services/auth.service";
import useQueryString from "../../hooks/useQueryString";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { ILoginResponseValues, ILoginValues } from "../../interfaces/auth";

import "./style.scss";

const Auth: FC = () => {
  const navigate = useNavigate();
  const currentUser = useAuth();
  const location: ILocation = useLocation();
  const queryString = useQueryString();

  const { t, i18n } = useTranslation(["auth", "form", "main"]);
  useDocumentTitle(t("login"));

  const emailText = t("email", { ns: ["form"] });
  const passwordText = t("password", { ns: ["form"] });

  const [form] = Form.useForm();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);

  const passwordRules = [
    {
      required: true,
      message: t("inputRequired", { inputName: passwordText, ns: ["form"] }) || "Please input your password!",
    },
    {
      max: 50,
      message:
        t("inputLimit", { inputName: passwordText, limit: "50", ns: ["form"] }) ||
        "Password should not exceed 50 characters",
    },
  ];

  useEffect(() => {
    // if the user is already logged in, no need to do it again
    if (currentUser) {
      setRedirectToReferrer(true);
    }
  }, [currentUser]);

  if (redirectToReferrer) {
    let redirectUrl = null;
    if (typeof location.state == "string") {
      const state = JSON.parse(location.state);
      redirectUrl = state?.from;
    }

    return <Navigate to={redirectUrl || URL.DASHBOARD} replace />;
  }

  const onFinish = (values: ILoginValues) => {
    setError("");
    setLoading(true);

    AuthService.login(values).then(
      (response: { status: number; data: ILoginResponseValues }) => {
        if (response?.status === 200) {
          if (response.data.tokenType) {
            let redirectUrl = "";
            if (response.data.tokenType === 2) {
              redirectUrl = queryString.get("redirect") || URL.DASHBOARD;
              setUser(response.data);
            } else {
              redirectUrl = `${URL.MFA}?mfaToken=${response.data.token}`;
            }
            navigate(redirectUrl);
          } else {
            setLoading(false);
            setError(t("unknownErr", { ns: ["form"] }) || "Unknown error occurred.");
          }
        } else if (response.status === 400) {
          setLoading(false);
          setError(t("incorrectUsernamePasswordText", { ns: ["auth"] }) || "Incorrect Username or Password");
        } else {
          setLoading(false);
          setError(t("unknownErr", { ns: ["form"] }) || "Unknown error occurred.");
        }
      },
      err => {
        const resMessage = err.response?.data?.message || err.message || err.toString();

        setError(resMessage);
        setLoading(false);
      }
    );
  };

  const renderForm = () => {
    return (
      <Form form={form} name="login_form" layout="vertical" onFinish={onFinish} autoComplete="off">
        {error && <Alert message={error} type="error" showIcon />}

        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: t("inputRequired", { inputName: emailText, ns: ["form"] }) || "Please input your E-mail!",
            },
            {
              type: "email",
              message: t("inputInvalid", { inputName: emailText, ns: ["form"] }) || "Invalid E-mail",
            },
            {
              max: 50,
              message:
                t("inputLimit", { inputName: emailText, limit: "50", ns: ["form"] }) ||
                "E-mail should not exceed 50 characters",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            disabled={loading}
            placeholder={emailText}
            autoComplete="username"
          />
        </Form.Item>

        <Form.Item name="password" rules={passwordRules}>
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            disabled={loading}
            placeholder={passwordText}
            autoComplete="current-password"
          />
        </Form.Item>

        <Form.Item className="form-actions">
          <Button type="primary" htmlType="submit" className="login-form-button" loading={loading} block>
            {loading ? t("pleaseWait", { ns: ["main"] }) : t("login", { ns: ["auth"] })}
          </Button>
        </Form.Item>

        <div className="form-forgot-password">
          <Link to={URL.FORGOT_PASSWORD}>{t("forgotPassword", { ns: ["auth"] })}?</Link>
        </div>

        <div className="form-register">
          <Divider>{t("or", { ns: ["form"] })}</Divider>
          {t("notMember", { ns: ["auth"] })}? <Link to={URL.REGISTER}>{t("signUp", { ns: ["auth"] })}</Link>
        </div>
      </Form>
    );
  };

  return (
    <div className="login-page" id="loginPage">
      <div className="login-form">
        <div className="login-form-header">
          <Typography.Title>{t("login", { ns: ["auth"] })}</Typography.Title>
        </div>

        {renderForm()}

        <div className="select-language-container">
          <SelectLanguage popupContainerElem={document.getElementById("loginPage")} />
        </div>
      </div>
    </div>
  );
};

export default Auth;
