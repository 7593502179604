import { FC, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Button, Space, notification } from "antd";

import AuthService from "../../../services/auth.service";

interface IVerifyPhoneNumberProps {
  phoneNumber: string | undefined;
  verifyMutation: ({ phoneNumber, token }: { phoneNumber: string; token: string }) => Promise<void>;
}

const VerifyPhoneNumber: FC<IVerifyPhoneNumberProps> = ({ phoneNumber, verifyMutation }) => {
  const { t } = useTranslation(["form"]);
  const [api, contextHolder] = notification.useNotification();

  const [token, setToken] = useState<string>("");
  const [phone, setPhone] = useState<string>(phoneNumber || "");
  const [submitting, setSubmitting] = useState<"generate" | "verify" | undefined>(undefined);

  useEffect(() => {
    if (phoneNumber) {
      setPhone(phoneNumber);
    }
  }, [phoneNumber]);

  const handleGenerateOTP = async () => {
    setSubmitting("generate");
    const response = await AuthService.requestPhoneNumVerificationCode(phone);
    if (response?.status === 200) {
      api.success({
        message: t("otpSentSuccessText", { ns: ["form"] }),
      });
    } else {
      api.error({
        message: t("otpSentErrorText", { ns: ["form"] }),
      });
    }
    setSubmitting(undefined);
  };

  const handleVerify = async () => {
    setSubmitting("verify");
    await verifyMutation({ phoneNumber: phone, token });
    setSubmitting(undefined);
  };

  return (
    <>
      {contextHolder}

      <div className="ant-form-item-control-input-content">
        <input
          value={phone}
          placeholder={t("inputPhoneNumText", { ns: ["form"] }) || "Input phone number"}
          className="ant-input ant-input-outlined"
          onChange={e => setPhone(e.target.value)}
          disabled={Boolean(submitting)}
        />
      </div>

      <div className="ant-form-item-control-input__action">
        <Space size={10}>
          <Button
            htmlType="button"
            disabled={!phone || Boolean(submitting)}
            loading={submitting === "generate"}
            onClick={handleGenerateOTP}
          >
            {t("generateOtpText", { ns: ["form"] })}
          </Button>
          <div className="ant-form-item-control-input-content">
            <input
              className="ant-input ant-input-outlined"
              value={token}
              onChange={e => setToken(e.target.value)}
              maxLength={6}
              style={{ width: 100, textAlign: "center" }}
              disabled={submitting === "verify"}
            />
          </div>
          <Button
            htmlType="button"
            disabled={token.length < 6}
            onClick={handleVerify}
            loading={submitting === "verify"}
          >
            {t("verifyText", { ns: ["form"] })}
          </Button>
        </Space>
      </div>
    </>
  );
};

export default VerifyPhoneNumber;
