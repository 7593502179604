import { FC, cloneElement } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { Divider, Dropdown, Layout, Menu, MenuProps, theme } from "antd";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";

import PAGES from "../../utils/pages";
import useAuth from "../../hooks/useAuth";
import { URL } from "../../utils/constants";
import { clearStorage, getInitials } from "../../utils/utility";

import Logo from "../../assets/images/logo.png";

const { Header } = Layout;
const { useToken } = theme;

const TopBar: FC = () => {
  const user = useAuth();
  const { token } = useToken();
  const { t } = useTranslation(["main", "page"]);

  const getMenuList = () => {
    const menuItems = PAGES.filter(page => !page.subPage && !page.userPage).map(page => ({
      label: <Link to={page.link}>{t(page.key, { ns: ["page"] })}</Link>,
      key: page.link,
    }));

    return menuItems;
  };

  const getUserMenuList = () => {
    const fullName = `${user?.firstName} ${user?.lastName}`;
    const contentStyle: React.CSSProperties = {
      backgroundColor: token.colorBgElevated,
      borderRadius: token.borderRadiusLG,
      boxShadow: token.boxShadowSecondary,
    };

    const logout = () => {
      clearStorage();
      location.reload();
    };

    let items: MenuProps["items"] = [];
    const pageItems = PAGES.filter(page => page.userPage).map(page => ({
      label: (
        <Link to={page.link}>
          {page.icon}
          {page.icon ? " " : ""}
          {t(page.key, { ns: ["page"] })}
        </Link>
      ),
      key: page.link,
    }));
    items = items.concat(pageItems);
    items.push({
      label: (
        <div onClick={() => logout()} className="logout-btn">
          <LogoutOutlined /> {t("logOut", { ns: ["page"] })}
        </div>
      ),
      key: "logout",
    });

    return (
      <Dropdown
        menu={{ items }}
        trigger={["click"]}
        placement="bottomRight"
        overlayClassName="user-dropdown"
        dropdownRender={menu => (
          <div style={contentStyle}>
            <div className="user-dropdown__name">
              <UserOutlined /> {fullName}
            </div>
            <Divider style={{ margin: 0 }} />
            {cloneElement(menu as React.ReactElement)}
          </div>
        )}
      >
        <div className="user-menu">{getInitials(`${fullName}`)}</div>
      </Dropdown>
    );
  };

  return (
    <Header className="app-header">
      <div className="app-container">
        <div
          style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "flex-end" }}
        >
          <Link to={URL.DASHBOARD} className="app-logo">
            <img src={Logo} />
          </Link>
          <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
            <Menu className="app-menu" mode="horizontal" selectedKeys={[location.pathname]} items={getMenuList()} />
            {getUserMenuList()}
          </div>
        </div>
      </div>
    </Header>
  );
};

export default TopBar;
