import i18n from "i18next";
import { initReactI18next } from "react-i18next";

//Import all english translation files
import translationEnglish from "./locales/English/main.json";
import translationEnglishForm from "./locales/English/form.json";
import translationEnglishAuth from "./locales/English/auth.json";
import translationEnglishPages from "./locales/English/pages.json";
import translationEnglishEnum from "./locales/English/enum.json";

//Import all japanese translation files
import translationJapanese from "./locales/Japanese/main.json";
import translationJapaneseForm from "./locales/Japanese/form.json";
import translationJapaneseAuth from "./locales/Japanese/auth.json";
import translationJapanesePages from "./locales/Japanese/pages.json";
import translationJapaneseEnum from "./locales/Japanese/enum.json";

import { getDefaultLanguage } from "../utils/utility";

const resources = {
  en: {
    main: translationEnglish,
    form: translationEnglishForm,
    auth: translationEnglishAuth,
    page: translationEnglishPages,
    enum: translationEnglishEnum,
  },
  jp: {
    main: translationJapanese,
    form: translationJapaneseForm,
    auth: translationJapaneseAuth,
    page: translationJapanesePages,
    enum: translationJapaneseEnum,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: getDefaultLanguage()?.key || "jp", //default language
  fallbackLng: "jp",
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
});

export default i18n;
