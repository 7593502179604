export const QuestionnaireTypeEnum = [
  { label: "Text-to-speech", value: 1, key: "textToSpeech" },
  { label: "Audio file", value: 2, key: "audioFile" },
  { label: "Questionnaire", value: 3, key: "questionnaire" },
];

export const GenderEnum = [
  { label: "Male", value: 1, key: "male" },
  { label: "Female", value: 2, key: "female" },
];

export const AutoCallStatusEnum = [
  { label: "Scheduled", value: 1, key: "scheduled" },
  { label: "In Call", value: 2, key: "inCall" },
  { label: "Finished", value: 3, key: "finished" },
];

export const AutoCallStatusEnumLog = [
  { label: "Not Started", value: 1, key: "notStarted" },
  { label: "Started", value: 2, key: "started" },
  { label: "Not Answered", value: 3, key: "notAnswered" },
  { label: "Completed", value: 4, key: "completed" },
  { label: "Error", value: 5, key: "error" },
];
