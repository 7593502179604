import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import _ from "lodash";

import { useTranslation } from "react-i18next";

import { Button, Popconfirm, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { CopyOutlined, DeleteOutlined, EditOutlined, LineChartOutlined, ProjectOutlined } from "@ant-design/icons";

import AutoCallService from "../../services/autoCall.service";
import { IAutoCall } from "../../interfaces/autoCall";

import { AutoCallStatusEnum } from "../../utils/enums";
import { IPageProps } from "../../interfaces/common";
import { DEFAULT_REGISTRATION_GROUP_KEY, URL } from "../../utils/constants";
import { removeFromStorage } from "../../utils/utility";
import useDocumentTitle from "../../hooks/useDocumentTitle";

import "./style.scss";
import ReportDrawer from "./drawer";

const AutoCall: FC<IPageProps> = (props: IPageProps) => {
  const { t } = useTranslation(["page", "enum", "main"]);
  useDocumentTitle(t("autoCall", { ns: ["page"] }) || props.pageMetaTitle);

  const navigate = useNavigate();

  const [data, setData] = useState<IAutoCall[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showDrawer, setShowDrawer] = useState<number | undefined>();

  useEffect(() => {
    removeFromStorage(DEFAULT_REGISTRATION_GROUP_KEY);
    (async () => {
      await fetchData();
    })();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    const response = await AutoCallService.get();
    if (response?.status === 200) {
      const resData: IAutoCall[] = [];
      response.data.forEach((thisData: IAutoCall, index: number) => {
        resData.push({
          ...thisData,
          key: index + 1,
          startAt: dayjs(thisData.startAt).format("YYYY/MM/DD HH:mm"),
        });
      });
      setData(resData);
    } else {
      // eslint-disable-next-line no-console
      console.log(">>> error");
    }
    setIsLoading(false);
  };

  const deleteData = async (columnId: number) => {
    const response = await AutoCallService.delete(columnId);
    if (response?.status === 200) {
      const newData = [...data].filter(d => d.id !== columnId);
      setData(newData);
    }
  };

  const cloneData = async (columnId: number) => {
    setIsLoading(true);
    const response = await AutoCallService.duplicate(columnId);
    if (response?.status === 200) {
      await fetchData();
    }
  };

  const columns: ColumnsType<IAutoCall> = [
    // {
    //   title: "#",
    //   dataIndex: "key",
    //   key: "key",
    //   width: 40,
    // },
    {
      title: t("nameText", { ns: ["main"] }),
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: t("dateTimeText", { ns: ["main"] }),
      dataIndex: "startAt",
      key: "startAt",
      width: 130,
    },
    {
      title: t("statusText", { ns: ["main"] }),
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (status: number) => {
        const thisStatus = AutoCallStatusEnum.find(g => g.value === status)?.key || "scheduled";
        return t(thisStatus, { ns: ["enum"] });
      },
    },
    {
      title: t("remarksText", { ns: ["main"] }),
      dataIndex: "remarks",
      key: "remarks",
      render: (remarks: string) => {
        return remarks !== "undefined" ? remarks : "";
      },
    },
    {
      title: t("actionText", { ns: ["main"] }),
      key: "operation",
      fixed: "right",
      width: 170,
      render: (columnData: IAutoCall) => (
        <Space>
          <Button
            type="primary"
            shape="circle"
            title={t("editText", { ns: ["main"] }) || "Edit"}
            onClick={() => navigate(`${URL.AUTO_CALL}/edit/${columnData.id}`)}
            disabled={columnData.status !== 1}
          >
            <EditOutlined />
          </Button>
          <Popconfirm
            placement="topLeft"
            title={t("cloneRecordQuestion", { ns: ["main"] }) || "Are you sure to clone this record?"}
            onConfirm={() => cloneData(columnData?.id || 0)}
            okText={t("yesText", { ns: ["main"] }) || "Yes"}
            cancelText={t("noText", { ns: ["main"] }) || "N0"}
          >
            <Button type="primary" shape="circle" title={t("cloneText", { ns: ["main"] }) || "Clone"}>
              <CopyOutlined />
            </Button>
          </Popconfirm>
          <Button
            type="primary"
            shape="circle"
            title={t("analysisText", { ns: ["main"] }) || "Analysis"}
            onClick={() => setShowDrawer(columnData?.id)}
          >
            <LineChartOutlined />
          </Button>
          <Popconfirm
            placement="topLeft"
            title={t("deleteRecordQuestion", { ns: ["main"] }) || "Are you sure to delete this record?"}
            onConfirm={() => deleteData(columnData?.id || 0)}
            okText={t("yesText", { ns: ["main"] }) || "Yes"}
            cancelText={t("noText", { ns: ["main"] }) || "No"}
            disabled={columnData.status !== 1}
          >
            <Button
              type="primary"
              shape="circle"
              title={t("deleteText", { ns: ["main"] }) || "Delete"}
              disabled={columnData.status !== 1}
            >
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="auto-call-page">
        <div className="app-container">
          <div className="table-container">
            <div className="table-container__header">
              <div>
                {data.length} {t("casesText", { ns: ["main"] })}
              </div>
              <div className="table-container__header__actions">
                <Space>
                  <Button type="primary" onClick={() => navigate(URL.PHONE_BOOK)}>
                    {t("addNewText", { ns: ["main"] })}
                  </Button>
                </Space>
              </div>
            </div>

            <Table
              columns={columns}
              dataSource={data}
              loading={isLoading}
              locale={{ emptyText: t("noData", { ns: ["page"] }) }}
            />
          </div>
        </div>
      </div>

      <ReportDrawer showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
    </>
  );
};

export default AutoCall;
