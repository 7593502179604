import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";

import { useTranslation } from "react-i18next";

import { Alert, Button, Divider, Form, Input, Result, Typography } from "antd";

import { URL, REGEX_LOWER_CASE, REGEX_UPPER_CASE, REGEX_DIGI_CASE, REGEX_SPECIAL_CASE } from "../../utils/constants";
import { getUrlParams } from "../../utils/utility";
import AuthService from "../../services/auth.service";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { IResetPasswordValues } from "../../interfaces/auth";

const ActivateAccount: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["auth", "form", "main"]);
  useDocumentTitle(t("activateAccount", { ns: ["auth"] }) || "Activate Account");

  const initialValues: IResetPasswordValues = {
    email: getUrlParams("email") || "",
    token: getUrlParams("token") || "",
    password: "",
  };

  if (!getUrlParams("email") || !getUrlParams("token")) {
    return (
      <div className="login-page" id="loginPage">
        <Result
          status="error"
          title={t("invalidUrl", { ns: ["main"] })}
          subTitle={t("invalidUrlSubtitle", { ns: ["main"] })}
          extra={[
            <Link className="ant-btn ant-btn-primary" to={URL.LOGIN} key="buy">
              {t("goToLogin", { ns: ["auth"] })}
            </Link>,
          ]}
        />
      </div>
    );
  }

  const emailText = t("email", { ns: ["auth"] });
  const passwordText = t("password", { ns: ["form"] });
  const confirmPasswordText = t("confirmPassword", { ns: ["auth"] });

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onFinish = async (values: IResetPasswordValues) => {
    setError("");
    setLoading(true);

    const response = await AuthService.activateAccount(values);
    if (response.status === 200) {
      navigate(URL.DASHBOARD);
    } else {
      let errMessage = t("formErrorText", { ns: ["form"] }) || "Error in submitting form";
      if (response?.data) {
        const dataParse = JSON.parse(response.data);
        const errors = dataParse ? Object.values(dataParse) : null;
        if (errors?.length) {
          const firstErrMessage = _.head(errors);
          if (_.isArray(firstErrMessage) && firstErrMessage?.length) {
            errMessage = _.head(firstErrMessage);
          }
        }
      }
      setError(errMessage);
    }
    setLoading(false);
  };

  const renderForm = () => {
    return (
      <Form
        form={form}
        name="login_form"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        initialValues={initialValues}
      >
        {error && <Alert message={error} type="error" showIcon />}
        <Form.Item name="token" label="Token" rules={[{ required: true }]} style={{ display: "none" }}>
          <Input disabled={loading} readOnly />
        </Form.Item>
        <Form.Item
          name="email"
          label={emailText}
          rules={[
            {
              required: true,
              message: t("inputRequired", { inputName: emailText, ns: ["form"] }) || "Please input your Email!",
            },
            {
              type: "email",
              message: t("inputInvalid", { inputName: emailText, ns: ["form"] }) || "Invalid Email",
            },
            {
              max: 50,
              message:
                t("inputLimit", { inputName: emailText, limit: "50", ns: ["form"] }) ||
                "Email should not exceed 50 characters",
            },
          ]}
        >
          <Input type="email" disabled={loading} readOnly />
        </Form.Item>
        <Form.Item
          name="password"
          label={passwordText}
          rules={[
            ({ getFieldValue }) => ({
              validator(__, value) {
                if (value?.length) {
                  if (value?.length >= 8) {
                    if (REGEX_LOWER_CASE.test(value)) {
                      if (REGEX_UPPER_CASE.test(value)) {
                        if (REGEX_DIGI_CASE.test(value)) {
                          if (REGEX_SPECIAL_CASE.test(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              t("mustContainSpecialCharsValidation", { inputName: passwordText, ns: ["form"] }) ||
                                "Must contain at least one special character [!@#$%^&^*()_=+]."
                            );
                          }
                        } else {
                          return Promise.reject(
                            t("mustContainNumberValidation", { inputName: passwordText, ns: ["form"] }) ||
                              "Must contain at least one number [0-9]."
                          );
                        }
                      } else {
                        return Promise.reject(
                          t("upperCaseValidation", { inputName: passwordText, ns: ["form"] }) ||
                            "Must contain at least one uppercase letter [A-Z]."
                        );
                      }
                    } else {
                      return Promise.reject(
                        t("lowerCaseValidation", { ns: ["form"] }) ||
                          "Must contain at least one lowercase letter [a-z]."
                      );
                    }
                  } else {
                    return Promise.reject(
                      t("minCharacterLimit", { limit: 8, ns: ["form"] }) || "Minimum character limit is 8."
                    );
                  }
                } else {
                  return Promise.reject(
                    t("inputRequired", { inputName: passwordText, ns: ["form"] }) || "Please input your Password!"
                  );
                }
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirm"
          label={confirmPasswordText}
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: t("confirmPasswordText", { ns: ["auth"] }) || "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(__, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t("confirmPasswordMismatch", { ns: ["auth"] }) || ""));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item className="form-actions">
          <Button type="primary" htmlType="submit" className="login-form-button" loading={loading} block>
            {loading ? t("pleaseWait", { ns: ["main"] }) : t("activate", { ns: ["main"] })}
          </Button>
        </Form.Item>
        <div className="form-register">
          <Divider></Divider>
          <div>
            {t("notMember", { ns: ["auth"] })}? <Link to={URL.REGISTER}>{t("signUp", { ns: ["auth"] })}</Link>
          </div>
          <div style={{ marginTop: 15 }}>
            {t("alreadyHaveAccount", { ns: ["auth"] })}? <Link to={URL.LOGIN}>{t("login", { ns: ["auth"] })}</Link>
          </div>
        </div>
      </Form>
    );
  };

  return (
    <div className="login-page" id="loginPage">
      <div className="login-form">
        <div className="login-form-header">
          <Typography.Title>{t("activateAccount", { ns: ["auth"] })}</Typography.Title>
          <Typography.Title level={3}>{t("enterPasswordActivateAccount", { ns: ["auth"] })}</Typography.Title>
        </div>

        {renderForm()}
      </div>
    </div>
  );
};

export default ActivateAccount;
