import API from "./axios";

class ReportService {
  getUsage = () => {
    return API.get("/api/Reports/usage")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  getTotalCases = () => {
    return API.get("/api/Reports/total-cases")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };
}

export default new ReportService();
