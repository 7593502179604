import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { Button, Space, Popconfirm, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { CopyOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { URL } from "../../utils/constants";
import { IPageProps } from "../../interfaces/common";
import useDocumentTitle from "../../hooks/useDocumentTitle";

import { ISurvey } from "../../interfaces/survey";
import SurveyService from "../../services/survey.service";

import "./style.scss";

const Questionnaire: FC<IPageProps> = (props: IPageProps) => {
  const { t } = useTranslation(["page", "enum"]);
  useDocumentTitle(t("questionnaire", { ns: ["page"] }) || props.pageMetaTitle);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<ISurvey[]>([]);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    const response = await SurveyService.get();
    if (response?.status === 200) {
      const resData: ISurvey[] = [];
      response.data.forEach((thisData: ISurvey, index: number) => {
        resData.push({
          ...thisData,
          key: index + 1,
        });
      });
      setData(resData);
    } else {
      // eslint-disable-next-line no-console
      console.log(">>> error");
    }
    setIsLoading(false);
  };

  const cloneData = async (columnId: number) => {
    setIsLoading(true);
    const response = await SurveyService.duplicate(columnId);
    if (response?.status === 200) {
      await fetchData();
    }
  };

  const deleteData = (columnId: number) => {
    console.log("deleteData", columnId);
  };

  const columns: ColumnsType<ISurvey> = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      width: 40,
    },
    {
      title: t("nameText", { ns: ["main"] }),
      dataIndex: "name",
      key: "name",
      width: 200,
      ellipsis: true,
    },
    {
      title: t("remarksText", { ns: ["main"] }),
      dataIndex: "remarks",
      key: "remarks",
      ellipsis: true,
    },
    {
      title: t("actionText", { ns: ["main"] }),
      key: "operation",
      fixed: "right",
      width: 135,
      render: (columnData: ISurvey) => (
        <Space>
          <Button
            type="primary"
            shape="circle"
            title={t("editText", { ns: ["main"] }) || "Edit"}
            onClick={() => navigate(`${URL.QUESTIONNAIRE}/edit/${columnData.id}`)}
          >
            <EditOutlined />
          </Button>
          <Popconfirm
            placement="topLeft"
            title={t("cloneRecordQuestion", { ns: ["main"] }) || "Are you sure to clone this record?"}
            onConfirm={() => cloneData(columnData?.id || 0)}
            okText={t("yesText", { ns: ["main"] }) || "Yes"}
            cancelText={t("noText", { ns: ["main"] }) || "N0"}
          >
            <Button type="primary" shape="circle" title={t("cloneText", { ns: ["main"] }) || "Clone"}>
              <CopyOutlined />
            </Button>
          </Popconfirm>
          {/* <Popconfirm
            placement="topLeft"
            title="Are you sure to delete this record?"
            onConfirm={() => deleteData(columnData?.id || 0)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger type="primary" shape="circle" title="Delete">
              <DeleteOutlined />
            </Button>
          </Popconfirm> */}
        </Space>
      ),
    },
  ];

  return (
    <div className="questionnaire-page">
      <div className="app-container">
        <div className="table-container">
          <div className="table-container__header">
            <div>
              {data.length} {t("casesText", { ns: ["main"] })}
            </div>
            <div className="table-container__header__actions">
              <Space>
                <Button onClick={() => navigate(URL.QUESTIONNAIRE_ADD)} type="primary">
                  {t("addNewText", { ns: ["main"] })}
                </Button>
              </Space>
            </div>
          </div>

          <Table
            columns={columns}
            dataSource={data}
            loading={isLoading}
            locale={{ emptyText: t("noData", { ns: ["page"] }) }}
          />
        </div>
      </div>
    </div>
  );
};

export default Questionnaire;
