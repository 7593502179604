import { FC, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import _ from "lodash";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
dayjs.extend(utc);

import type { SelectProps } from "antd";
import { ArrowLeftOutlined, MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert, Button, Card, DatePicker, Form, Input, Radio, Select, Space, Spin, Tag } from "antd";

import Loading from "../../components/Loading";

import AutoCallService from "../../services/autoCall.service";
import SurveyService from "../../services/survey.service";
import { IAutoCallData, IAutoCallForm } from "../../interfaces/autoCall";
import { ISurvey } from "../../interfaces/survey";

import { IPageProps } from "../../interfaces/common";
import { UsageContext } from "../../context/usageContext";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { formatCurrency, getFromStorage, textToSpeech } from "../../utils/utility";
import { GenderEnum, QuestionnaireTypeEnum } from "../../utils/enums";
import { URL, DEFAULT_REGISTRATION_GROUP_KEY } from "../../utils/constants";

import "./form.scss";

type LabelRender = SelectProps["labelRender"];
interface IRegistrationGroupData {
  [key: string]: string;
}

const AutoCallForm: FC<IPageProps> = (props: IPageProps) => {
  const { id } = useParams();
  const { usage } = useContext(UsageContext);
  const availableBalance = usage ? Number(usage.availableBalance) : 0;

  const { t, i18n } = useTranslation(["page", "form", "enum", "main"]);
  useDocumentTitle(t(id ? "editAutoCall" : "addAutoCall", { ns: ["page"] }) || props.pageMetaTitle);

  const nameText = t("nameText", { ns: ["main"] });
  const startAtText = t("startAtText", { ns: ["main"] });
  const addressText = t("addressText", { ns: ["main"] });
  const remarksText = t("remarksText", { ns: ["main"] });
  const questionnaireText = t("questionnaire", { ns: ["enum"] });

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [error, setError] = useState("");
  const [file, setFile] = useState<File>();
  const [fileUrl, setFileUrl] = useState<string>("");
  const [projectedCost, setProjectedCost] = useState<number | undefined>(0);
  const [callContent, setCallContent] = useState<number>(1);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [projectedCosts, setProjectedCosts] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(id ? true : false);
  const [questionnaireIds, setQuestionnaireIds] = useState<number[]>([]);
  const [questionnaireData, setQuestionnaireData] = useState<ISurvey[]>([]);
  const [addressRegistration, setAddressRegistration] = useState<string[]>([]);
  const [registrationGroups, setRegistrationGroups] = useState<IRegistrationGroupData | null>(null);
  const [registrationGroupData, setRegistrationGroupData] = useState<IRegistrationGroupData | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setRegistrationGroup = (thisRegistrationGroupData: any) => {
    setRegistrationGroupData(thisRegistrationGroupData);
    const thisRegistrationGroups = { ...thisRegistrationGroupData };
    if (thisRegistrationGroups?.count) {
      delete thisRegistrationGroups.count;
    }
    setRegistrationGroups(thisRegistrationGroups);
    const thisAddressRegistration = Object.keys(thisRegistrationGroups).map((a: string) => {
      if (a === "gender") {
        const thisGender = GenderEnum.find(g => g.value === thisRegistrationGroups[a])?.key || "male";
        return t(thisGender, { ns: ["enum"] });
      }
      return thisRegistrationGroups[a];
    });
    if (thisAddressRegistration) {
      setAddressRegistration(thisAddressRegistration);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchQuestionnaire();

      if (id) {
        await fetchData(Number(id));
      } else {
        const registrationGroupStore = getFromStorage(DEFAULT_REGISTRATION_GROUP_KEY);
        const thisRegistrationGroupData = registrationGroupStore ? JSON.parse(registrationGroupStore) : null;

        if (!thisRegistrationGroupData) {
          navigate(URL.AUTO_CALL);
        }

        setRegistrationGroup(thisRegistrationGroupData);
        form.setFieldsValue({
          type: 1,
          remarks: "",
        });
      }
    })();
  }, [id]);

  const addProjectedCost = async (value: string, index: number, groups: IRegistrationGroupData | null) => {
    setProjectedCost(undefined);
    if (!groups) {
      setProjectedCost(prevVal => prevVal || 0);
      return;
    }
    const thisData = {
      ...groups,
    };
    if ("gender" in groups) {
      thisData.gender = `${groups.gender}`;
    }

    const response = await AutoCallService.getProjectedCost(value, thisData);
    if (response?.status === 200) {
      const cost = Number(response.data.projectedCost);
      const thisProjectCosts = [...projectedCosts];
      if (thisProjectCosts.length > index) {
        thisProjectCosts[index] = cost;
      } else {
        thisProjectCosts.push(cost);
      }
      setProjectedCosts(thisProjectCosts);

      const sum = thisProjectCosts.reduce((accumulator, currentValue) => accumulator + currentValue);
      setProjectedCost(sum);
    } else {
      setProjectedCost(prevVal => prevVal || 0);
    }
  };

  const fetchData = async (pId: number) => {
    setIsLoading(true);
    const response = await AutoCallService.getById(pId);
    if (response?.status === 200) {
      const resData = response.data as IAutoCallData;
      const date = dayjs(resData.startAt).local();
      const surveys = resData.surveys;
      let SurveyIds: number[] = [];
      if (surveys) {
        const thisProjectCosts = Object.values(surveys);
        setProjectedCosts(thisProjectCosts);

        const sum = thisProjectCosts.reduce((accumulator, currentValue) => accumulator + currentValue);
        setProjectedCost(sum);

        SurveyIds = Object.keys(surveys).map(sid => Number(sid));
      }

      const thisData = {
        ...resData,
        startAt: dayjs(date, "YYYY/MM/DD HH:mm"),
        SurveyIds: SurveyIds.map(sid => ({ id: sid })),
        remarks: resData.remarks !== "undefined" ? resData.remarks : "",
      };
      setRegistrationGroup(thisData.phonebookSearchList);
      setCallContent(thisData.type);
      setFileUrl(thisData.content);
      setQuestionnaireIds(SurveyIds);

      form.setFieldsValue(thisData);
    }
    setIsLoading(false);
  };

  const fetchQuestionnaire = async () => {
    const response = await SurveyService.get();
    if (response?.status === 200) {
      setQuestionnaireData(response.data);
    }
  };

  const onFileChange = (event: React.FormEvent) => {
    const files = (event.target as HTMLInputElement).files;

    if (files && files.length > 0) {
      const thisFile = files[0];
      const isAudio = thisFile.type.includes("audio/");
      if (!isAudio) {
        form.setFieldValue("audioFile", null);
        setFile(undefined);
        alert(t("invalidFileTypeText", { ns: ["main"] } || "Invalid File type"));
        return false;
      }

      setFile(files[0]);
    }
  };

  const getIdsArrFromSurveyIds = (surveyIds: { id: number }[]) => {
    const selectedSurveyIds = [] as number[];
    surveyIds.forEach((a: { id: number }) => {
      if (a?.id) {
        selectedSurveyIds.push(a?.id);
      }
    });

    return selectedSurveyIds;
  };

  const handleSubmit = async (values: IAutoCallForm) => {
    setIsSubmitting(true);

    const formData = { ...values };
    if (values.type === 2) {
      if (file) {
        formData.audioFile = file;
        delete formData.content;
        delete formData.audioFileUrl;
      } else if (fileUrl?.includes("https://")) {
        formData.audioFileUrl = fileUrl;
        delete formData.audioFile;
      } else {
        delete formData.audioFile;
        delete formData.audioFileUrl;
        setError(t("pleaseSelectAudio", { ns: ["main"] }) || "Please select audio file!");
        return false;
      }
      delete formData.content;
      delete formData.surveyIds;
      delete formData.SurveyIds;
    } else if (values.type === 3) {
      delete formData.content;
      if (formData.SurveyIds) {
        const selectedSurveyIds = getIdsArrFromSurveyIds(formData.SurveyIds);
        delete formData.surveyIds;
        delete formData.SurveyIds;
        formData.surveyIds = selectedSurveyIds;
      }
    } else {
      delete formData.surveyIds;
      delete formData.SurveyIds;
    }
    formData.startAt = dayjs(values.startAt).utc().format();
    if (registrationGroups) {
      const thisData = {
        ...registrationGroups,
      };
      if ("gender" in registrationGroups) {
        thisData.gender = `${registrationGroups.gender}`;
      }
      formData.phonebookSearchList = thisData;
    }
    let response;
    if (id) {
      response = await AutoCallService.update(Number(id), formData);
    } else {
      response = await AutoCallService.add(formData);
    }
    if (response?.status === 200) {
      navigate(URL.AUTO_CALL);
    } else {
      setError(response.data);
    }

    setIsSubmitting(false);
  };

  if (isLoading) {
    return (
      <div className="auto-call-form-page">
        <div className="app-container">
          <Loading message={t("fetchingDataText", { ns: ["main"] })} />
        </div>
      </div>
    );
  }

  const renderTextToSpeechFormElem = () => {
    return (
      <div className="play-btn-container">
        <Button
          type="primary"
          onClick={() => textToSpeech(form.getFieldValue("content"), i18n.language === "jp" ? "ja" : i18n.language)}
        >
          {t("readText", { ns: ["main"] })}
        </Button>
        <Form.Item
          label={t("readingText", { ns: ["main"] })}
          name="content"
          rules={[
            {
              required: true,
              message: t("pleaseInputContentText", { ns: ["main"] }) || "Please input content!",
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </div>
    );
  };

  const renderAudioFileFormElem = () => {
    return (
      <Form.Item
        rules={[
          {
            required: true,
            message: t("pleaseSelectAudio", { ns: ["main"] }) || "Please select audio file!",
          },
        ]}
        name="audioFile"
      >
        <label className="choose-audio-file-label">
          <input type="file" onChange={onFileChange} accept="audio/*" />
          <div className="choose-file-btn">{t("chooseAudioFileText", { ns: ["main"] })}</div>
          <span>{file?.name}</span>
        </label>
      </Form.Item>
    );
  };

  const renderQuestionnaireFormElem = () => {
    const subtractProjectedCost = (index: number) => {
      const thisProjectCosts = [...projectedCosts];
      thisProjectCosts.splice(index, 1);
      setProjectedCosts(thisProjectCosts);

      let sum = 0;
      if (thisProjectCosts.length) {
        sum = thisProjectCosts.reduce((accumulator, currentValue) => accumulator + currentValue);
      }
      setProjectedCost(sum);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const labelRender: LabelRender = (propsVal: any) => {
      const { value } = propsVal;

      const thisQuestion = questionnaireData.find(q => q.id == value);
      if (!thisQuestion) {
        return value;
      }

      return thisQuestion.name;
    };

    const renderQuestionnaireSelect = (
      key: number,
      name: number,
      restField: {
        fieldKey?: number | undefined;
        isListField?: boolean | undefined;
      }
    ) => {
      const selectedSurveyIds = getIdsArrFromSurveyIds(form.getFieldValue("SurveyIds"));
      const thisSurveyId = selectedSurveyIds[name];
      const availableSurveys = questionnaireData.filter(q => !selectedSurveyIds.includes(Number(q.id)));
      if (questionnaireIds.includes(thisSurveyId)) {
        const thisQuestion = questionnaireData.find(q => q.id === thisSurveyId);
        if (thisQuestion) {
          availableSurveys.push(thisQuestion);
        }
      }
      const sortedAvailableSurveys = _.sortBy(availableSurveys, ["name"]);

      const options = sortedAvailableSurveys.map(q => ({
        label: q.name,
        value: `${q.id}`,
      }));

      return (
        <Form.Item
          {...restField}
          name={[name, "id"]}
          style={{ width: "100%", marginBottom: 10 }}
          rules={[
            {
              required: true,
              message:
                t("inputRequired", { inputName: questionnaireText, ns: ["form"] }) || "Please select questionnaire",
            },
          ]}
        >
          <Select
            showSearch
            options={options}
            style={{ width: "calc(100% - 30px)" }}
            onChange={(value: string) => addProjectedCost(value, key, registrationGroups)}
            filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
            placeholder={
              t("inputRequired", { inputName: questionnaireText, ns: ["form"] }) || "Please select questionnaire"
            }
            labelRender={labelRender}
          />
        </Form.Item>
      );
    };

    return (
      <div style={{ marginBottom: "10px", width: "100%" }}>
        <Space size={20} style={{ marginBottom: 12, fontSize: 14 }}>
          <div style={{ fontWeight: "bold" }}>
            {t("availableBalanceText", { ns: ["page"] })}: {formatCurrency(availableBalance)}
          </div>
          <div style={{ fontWeight: "bold" }}>
            {t("projectedCostText", { ns: ["page"] })}:{" "}
            {projectedCost !== undefined ? (
              <Tag color={availableBalance > projectedCost ? "#87d068" : "#d9214e"}>
                {formatCurrency(projectedCost)}
              </Tag>
            ) : (
              <Spin />
            )}
          </div>
        </Space>
        <Form.List
          name="SurveyIds"
          rules={[
            {
              validator: async (__, value) => {
                if (!value || value.length === 0) {
                  return Promise.reject("Please select minimum one questionnaire!");
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div
                  key={key}
                  style={{ display: "flex", justifyContent: "flex-start", marginBottom: 1, width: "100%" }}
                >
                  {renderQuestionnaireSelect(key, name, restField)}
                  <div style={{ display: "flex", justifyContent: "center", height: 42, marginLeft: -20, zIndex: 1 }}>
                    <MinusCircleOutlined
                      onClick={() => {
                        remove(name);
                        subtractProjectedCost(key);
                      }}
                    />
                  </div>
                </div>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{ fontSize: 14 }}>
                  {t("addQuestionnaire", { ns: ["page"] })}
                </Button>

                <div style={{ marginTop: 5 }}>
                  <Form.ErrorList errors={errors} />
                </div>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
    );
  };

  const renderCallContentFormElem = () => {
    if (callContent === 3) {
      return renderQuestionnaireFormElem();
    } else if (callContent === 2) {
      return renderAudioFileFormElem();
    } else {
      return renderTextToSpeechFormElem();
    }
  };

  const renderForm = () => {
    return (
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Space direction="vertical" size="middle" style={{ display: "flex", width: "100%" }}>
          <Card>
            <Form.Item
              label={nameText}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("inputRequired", { inputName: nameText, ns: ["form"] }) || "Please input name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={startAtText}
              name="startAt"
              rules={[
                {
                  required: true,
                  message: t("inputRequired", { inputName: startAtText, ns: ["form"] }) || "Please input name!",
                },
              ]}
            >
              <DatePicker format="YYYY-MM-DD HH:mm" showTime={{ format: "HH:mm" }} />
            </Form.Item>
            <Form.Item label={addressText}>
              <Input
                style={{ border: "none", padding: 0 }}
                value={
                  registrationGroupData?.count
                    ? _.join(addressRegistration, " / ") + ` (${registrationGroupData?.count})`
                    : ""
                }
                readOnly
              />
            </Form.Item>
            <Form.Item label={t("callContentText", { ns: ["main"] })} name="type">
              <Radio.Group onChange={e => setCallContent(e.target.value)}>
                {QuestionnaireTypeEnum.map(type => (
                  <Radio value={type.value} key={type.value}>
                    {t(type.key, { ns: ["enum"] })}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <div style={{ marginTop: -15 }}>{renderCallContentFormElem()}</div>
            <Form.Item label={remarksText} name="remarks">
              <Input.TextArea />
            </Form.Item>
          </Card>
          <div className="ant-form-item form-actions">
            <Button htmlType="reset" disabled={isSubmitting}>
              {t("resetText", { ns: ["main"] })}
            </Button>
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              {isSubmitting ? t("submittingText", { ns: ["form"] }) : t("submitText", { ns: ["form"] })}
            </Button>
          </div>
        </Space>
      </Form>
    );
  };

  return (
    <div className="auto-call-form-page">
      <div className="app-container">
        <h1>
          <ArrowLeftOutlined onClick={() => navigate(URL.AUTO_CALL)} className="btn-back" />
          {t(id ? "editAutoCall" : "addAutoCall", { ns: ["page"] })}
        </h1>
        {error && <Alert message={error} type="error" showIcon closable />}
        {renderForm()}
      </div>
    </div>
  );
};

export default AutoCallForm;
