import React, { FC } from "react";

import { Layout } from "antd";

import TopBar from "./TopBar";

const { Content, Footer } = Layout;

import "./style.scss";

interface IAppLayoutProps {
  children: React.ReactNode;
}

const AppLayout: FC<IAppLayoutProps> = (props: IAppLayoutProps) => {
  return (
    <Layout className="app-layout">
      <TopBar />
      <Content className="main-content-layout">{props.children}</Content>
      <Footer className="app-footer">IVR © 2022.</Footer>
    </Layout>
  );
};

export default AppLayout;
