import { FC } from "react";

import { useTranslation } from "react-i18next";

import { Space } from "antd";

import News from "./sections/News";
import Usage from "./sections/Usage";

import { IPageProps } from "../../interfaces/common";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { getFromStorage } from "../../utils/utility";

import "./style.scss";
import { DEFAULT_USER_AUTH_KEY } from "../../utils/constants";

const Dashboard: FC<IPageProps> = (props: IPageProps) => {
  const user = getFromStorage(DEFAULT_USER_AUTH_KEY);
  const auth = JSON.parse(user || "");
  const { t } = useTranslation(["page"]);
  useDocumentTitle(t("dashboard", { ns: ["page"] }) || props.pageMetaTitle);

  return (
    <div className="dashboard-page">
      <div className="app-container">
        <Space direction="vertical" size={25} style={{ display: "flex" }}>
          {auth?.twilioNumber && (
            <div>
              {t("outboundNumber", { ns: ["page"] })}: <strong>{auth.twilioNumber}</strong>
            </div>
          )}

          <News />
          <Usage />
        </Space>
      </div>
    </div>
  );
};

export default Dashboard;
