import { FC, useContext, useEffect, useState } from "react";

import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import type { ColumnsType } from "antd/es/table";
import { Card, Col, Row, Table, Typography } from "antd";

import { formatCurrency } from "../../../utils/utility";
import ReportService from "../../../services/report.service";
import { UsageContext } from "../../../context/usageContext";
import { IUsage, ITotalCases } from "../../../interfaces/report";

const Usage: FC = () => {
  const { t } = useTranslation(["page"]);
  const { usage } = useContext(UsageContext);

  const [usageData, setUsageData] = useState<IUsage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [updatedDate, setUpdatedDate] = useState<string>("");
  const [totalCasesData, setTotalCasesData] = useState<ITotalCases[]>([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await fetchTotalCases();
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (usage) {
      const resData: IUsage[] = [];
      resData.push({
        ...usage,
        key: 1,
        lastUpdatedAt: dayjs(usage.lastUpdatedAt).format("YYYY/MM/DD HH:mm"),
      });
      setUsageData(resData);
    }
  }, [usage]);

  const fetchTotalCases = async () => {
    const response = await ReportService.getTotalCases();
    if (response?.status === 200) {
      setUpdatedDate(response.data.lastUpdatedAt);
      const resData: ITotalCases[] = [];
      if (!Object.values(response.data).every(d => d == null)) {
        resData.push({
          duration: response.data.duration,
          totalCases: response.data.totalCases,
          totalCost: response.data.totalCost,
          key: 1,
        });
        setTotalCasesData(resData);
      }
    }
  };

  const usageColumns: ColumnsType<IUsage> = [
    {
      title: t("lastUpdatedAtText", { ns: ["page"] }),
      dataIndex: "lastUpdatedAt",
    },
    {
      title: t("actualBalanceText", { ns: ["page"] }),
      dataIndex: "actualBalance",
      render: actualBalance => (actualBalance ? formatCurrency(actualBalance) : 0),
    },
    {
      title: t("availableBalanceText", { ns: ["page"] }),
      dataIndex: "availableBalance",
      render: availableBalance => (availableBalance ? formatCurrency(availableBalance) : 0),
    },
  ];

  const outgoingColumns: ColumnsType<ITotalCases> = [
    {
      title: t("numberText", { ns: ["page"] }),
      dataIndex: "totalCases",
    },
    {
      title: t("durationCallText", { ns: ["page"] }),
      dataIndex: "duration",
    },
    {
      title: t("totalCostText", { ns: ["page"] }),
      dataIndex: "totalCost",
      render: totalCost => (totalCost ? formatCurrency(totalCost) : 0),
    },
  ];

  return (
    <Card
      bordered={false}
      className="content-card dashboard-usage-card"
      title={
        <>
          {t("usageMonthText", { ns: ["page"] })}
          {updatedDate && <em>{t("updatedText", { ns: ["page"] })} 2022/12/10 08:23</em>}
        </>
      }
    >
      <Row gutter={30}>
        <Col span={12}>
          {/* <Typography.Title>{t("billingAmountText", { amount: billingAmount, ns: ["page"] })}</Typography.Title> */}
          <Typography.Title>{t("billingAmountText", { ns: ["page"] })}</Typography.Title>
          <Table
            columns={usageColumns}
            dataSource={usageData}
            pagination={false}
            loading={isLoading}
            locale={{ emptyText: t("noData", { ns: ["page"] }) }}
          />
        </Col>
        <Col span={12}>
          <Typography.Title>{t("outgoingText", { ns: ["page"] })}</Typography.Title>
          <Table
            columns={outgoingColumns}
            dataSource={totalCasesData}
            pagination={false}
            loading={isLoading}
            locale={{ emptyText: t("noData", { ns: ["page"] }) }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default Usage;
