import { FC } from "react";
import { Link } from "react-router-dom";

import { Result } from "antd";

import useDocumentTitle from "../../hooks/useDocumentTitle";

import "./style.scss";

const NoAccess: FC = () => {
  useDocumentTitle("403");

  return (
    <div className="not-found-page no-access-page">
      <Result
        status="403"
        title="NO ACCESS"
        subTitle="Sorry! You don’t have permissions to view this page.  Please contact your IFP project sponsor to request access."
        extra={
          <Link to="/" className="ant-btn ant-btn-primary">
            Back Home
          </Link>
        }
      />
    </div>
  );
};

export default NoAccess;
