import API from "./axios";

import { IPhoneBookForm, IPhoneBookBulkUploadFormData } from "../interfaces/phoneBook";

class PhoneBookService {
  get = () => {
    return API.get("/api/Phonebooks")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  getById = (id: number) => {
    return API.get(`/api/Phonebooks/${id}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  add = (formData: IPhoneBookForm) => {
    return API.post("/api/Phonebooks", formData)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  update = (id: number, formData: IPhoneBookForm) => {
    return API.put(`/api/Phonebooks/${id}`, formData)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  export = () => {
    return API.get("/api/Phonebooks/export", {
      maxBodyLength: Infinity,
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  import = (formData: IPhoneBookBulkUploadFormData) => {
    return API.post("/api/Phonebooks/import", formData)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  getCountryCodes = () => {
    return API.get("/api/Phonebooks/country-codes-select-options")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };
}

export default new PhoneBookService();
