import { FC, useState } from "react";

import { useTranslation } from "react-i18next";

import { Card, Empty, Typography } from "antd";
import Pagination from "../../../components/Pagination";

const newsData = [
  {
    id: 1,
    title: "News Title 1",
    date: "01/12/2022  10:00:00",
    description:
      "Contains the content of the notification. Contains the content of the notification. Contains the content of the notification. Contains the content of the notification. Contains the content of the notification.",
  },
  {
    id: 2,
    title: "News Title 2",
    date: "01/12/2022  10:00:00",
    description:
      "Contains the content of the notification. Contains the content of the notification. Contains the content of the notification. Contains the content of the notification. Contains the content of the notification.",
  },
  {
    id: 3,
    title: "News Title 3",
    date: "01/12/2022  10:00:00",
    description:
      "Contains the content of the notification. Contains the content of the notification. Contains the content of the notification. Contains the content of the notification. Contains the content of the notification.",
  },
];

const News: FC = () => {
  const { t } = useTranslation(["page"]);
  const [current, setCurrent] = useState(1);

  return (
    <Card bordered={false} className="content-card dashboard-news-card" title={t("newsText", { ns: ["page"] })}>
      <ul className="dashboard-news-lists">
        <li>
          <Empty />
        </li>
        {/* {newsData.map(item => (
          <li key={item.id}>
            <Typography.Title>
              {item.title}
              <small>{item.date}</small>
            </Typography.Title>
            <p>{item.description}</p>
          </li>
        ))} */}
      </ul>

      {/* <div className="pagination-container">
        <Pagination current={current} setCurrent={setCurrent} total={50} />
      </div> */}
    </Card>
  );
};

export default News;
