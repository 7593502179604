import { FC, useState } from "react";

import { useTranslation } from "react-i18next";
import { Button, Form, Modal, Select } from "antd";

import { ISettings } from "../../../interfaces/settings";

interface IEnableMfa {
  modalVisible: boolean;
  data: ISettings | undefined;
  setModalVisible: (visible: boolean) => void;
  enableMfaMutation: (defaultMfaProvider: string) => Promise<void>;
}

const EnableMfa: FC<IEnableMfa> = ({ data, modalVisible, setModalVisible, enableMfaMutation }) => {
  const { t } = useTranslation(["form"]);

  const [form] = Form.useForm();

  const [submitting, setSubmitting] = useState(false);

  const defaultMfaProviders = [
    {
      label: t("email", { ns: ["form"] }),
      value: 1,
    },
  ];

  if (data?.phoneNumber && data?.isPhoneNumberConfirmed) {
    defaultMfaProviders.push({
      label: t("phoneNumber", { ns: ["form"] }),
      value: 2,
    });
  }

  const handleSubmit = async (values: { defaultMfaProvider: string }) => {
    setSubmitting(true);
    await enableMfaMutation(values.defaultMfaProvider);
    handClose();
  };

  const handClose = () => {
    setModalVisible(false);
    setSubmitting(false);
    form.resetFields();
  };

  return (
    <Modal
      centered
      footer={null}
      open={modalVisible}
      title={t("enableMFAText", { ns: ["form"] })}
      maskClosable={false}
      onCancel={handClose}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item
          name="defaultMfaProvider"
          label={t("defaultMFAProviderText", { ns: ["form"] })}
          rules={[{ required: true }]}
        >
          <Select
            placeholder={t("selectDefaultMFAProviderText", { ns: ["form"] })}
            options={defaultMfaProviders}
            size="large"
            disabled={submitting}
          />
        </Form.Item>

        <div style={{ textAlign: "right" }}>
          <Button htmlType="submit" size="large" type="primary" loading={submitting}>
            {submitting ? t("enablingText", { ns: ["form"] }) : t("enableText", { ns: ["form"] })}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EnableMfa;
