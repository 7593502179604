import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import dayjs from "dayjs";
import _ from "lodash";
import Encoding from "encoding-japanese";

import { Button, Card, Col, Form, Input, message, Popconfirm, Row, Select, Space, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { SelectProps } from "antd/es/select";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import BulkUploadModal from "./BulkUploadModal";

import { URL, DEFAULT_REGISTRATION_GROUP_KEY } from "../../utils/constants";
import { GenderEnum } from "../../utils/enums";
import { removeFromStorage, setInStorage } from "../../utils/utility";
import { IPageProps } from "../../interfaces/common";
import { IPhoneBook, IPhoneBookSearchList } from "../../interfaces/phoneBook";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import PhoneBookService from "../../services/phoneBook.service";

import "./style.scss";

const PhoneBook: FC<IPageProps> = (props: IPageProps) => {
  const { t } = useTranslation(["page", "enum"]);
  useDocumentTitle(t("phoneBook", { ns: ["page"] }) || props.pageMetaTitle);

  const navigate = useNavigate();
  const [filterForm] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [data, setData] = useState<IPhoneBook[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [groups, setGroups] = useState<SelectProps["options"]>([]);
  const [filteredData, setFilteredData] = useState<IPhoneBook[]>([]);
  const [isBulkUploadModalOpen, setIsBulkUploadModalOpen] = useState<boolean>(false);

  useEffect(() => {
    removeFromStorage(DEFAULT_REGISTRATION_GROUP_KEY);

    (async () => {
      await fetchData();
    })();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    const response = await PhoneBookService.get();
    if (response?.status === 200) {
      const resData: IPhoneBook[] = [];
      response.data.forEach((thisData: IPhoneBook, index: number) => {
        resData.push({
          ...thisData,
          key: index + 1,
        });
      });
      setData(resData);
      const uniqueGroups: string[] = response.data
        .map((thisData: IPhoneBook) => thisData.registrationGroup)
        .filter((value: IPhoneBook, index: number, self: IPhoneBook[]) => self.indexOf(value) === index);
      setGroups(
        uniqueGroups.map(g => {
          return {
            value: g,
            label: _.capitalize(g),
          };
        })
      );
    } else {
      // eslint-disable-next-line no-console
      console.log(">>> error");
    }
    setIsLoading(false);
  };

  const deleteData = (columnId: number) => {
    console.log("deleteData", columnId);
  };

  const columns: ColumnsType<IPhoneBook> = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
    },
    {
      title: t("nameText", { ns: ["main"] }),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("phoneNumberText", { ns: ["main"] }),
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: t("dateOfBirthText", { ns: ["main"] }),
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      render: (dob: number) => (dob ? dayjs(dob).format("YYYY/MM/DD") : ""),
    },
    {
      title: t("genderText", { ns: ["main"] }),
      dataIndex: "gender",
      key: "gender",
      render: (gender: number) => {
        if (!gender) {
          return "";
        }
        const thisGender = GenderEnum.find(g => g.value === gender)?.key || "male";
        return t(thisGender, { ns: ["enum"] });
      },
    },
    {
      title: t("prefecturesText", { ns: ["main"] }),
      dataIndex: "prefectures",
      key: "prefectures",
    },
    {
      title: t("addressText", { ns: ["main"] }),
      dataIndex: "address",
      key: "address",
    },
    {
      title: t("other1Text", { ns: ["main"] }),
      dataIndex: "other1",
      key: "other1",
    },
    {
      title: t("other2Text", { ns: ["main"] }),
      dataIndex: "other2",
      key: "other2",
    },
    {
      title: t("other3Text", { ns: ["main"] }),
      dataIndex: "other3",
      key: "other3",
    },
    {
      title: t("registrationGroupText", { ns: ["main"] }),
      dataIndex: "registrationGroup",
      key: "registrationGroup",
    },
    {
      title: t("actionText", { ns: ["main"] }),
      key: "operation",
      fixed: "right",
      width: 100,
      render: (columnData: IPhoneBook) => (
        <Space>
          <Button
            type="primary"
            shape="circle"
            title={t("editText", { ns: ["main"] }) || "Edit"}
            onClick={() => navigate(`${URL.PHONE_BOOK}/edit/${columnData.id}`)}
          >
            <EditOutlined />
          </Button>
          {/* <Popconfirm
            placement="topLeft"
            title={t("cloneRecordQuestion", { ns: ["main"] }) || "Are you sure to clone this record?"}
            onConfirm={() => deleteData(columnData?.id || 0)}
            okText={t("yesText", { ns: ["main"] }) || "Yes"}
            cancelText={t("noText", { ns: ["main"] }) || "N0"}
          >
            <Button danger type="primary" shape="circle" title={t("deleteText", { ns: ["main"] }) || "Delete"}>
              <DeleteOutlined />
            </Button>
          </Popconfirm> */}
        </Space>
      ),
    },
  ];

  const getFilterFormData = (filterFormValues: IPhoneBookSearchList) => {
    if (!filterFormValues.name) {
      delete filterFormValues.name;
    }
    if (!filterFormValues.phoneNumber) {
      delete filterFormValues.phoneNumber;
    }
    if (!filterFormValues.agefrom) {
      delete filterFormValues.agefrom;
    }
    if (!filterFormValues.ageto) {
      delete filterFormValues.ageto;
    }
    if (!filterFormValues.gender) {
      delete filterFormValues.gender;
    }
    if (!filterFormValues.prefectures) {
      delete filterFormValues.prefectures;
    }
    if (!filterFormValues.address) {
      delete filterFormValues.address;
    }
    if (!filterFormValues.registrationgroup) {
      delete filterFormValues.registrationgroup;
    }

    if (!Object.keys(filterFormValues)?.length) {
      return null;
    } else {
      return filterFormValues;
    }
  };

  const onFilter = (values: IPhoneBookSearchList) => {
    setIsFiltered(false);
    const filterFormValues = getFilterFormData(values);
    if (!filterFormValues) {
      messageApi.open({
        type: "error",
        content: t("pleaseInputFilter", { ns: ["main"] }) || "Please input at least one filter",
      });
    } else {
      let thisFiltered = [...data];
      if (filterFormValues.name) {
        thisFiltered = [...thisFiltered].filter(d =>
          d.name?.toLocaleLowerCase().includes((filterFormValues.name || "")?.toLocaleLowerCase())
        );
      }
      if (filterFormValues.phoneNumber) {
        thisFiltered = [...thisFiltered].filter(d => d.phoneNumber.includes(filterFormValues.phoneNumber || ""));
      }
      if (filterFormValues.agefrom || filterFormValues.ageto) {
        const today = dayjs();
        if (filterFormValues.agefrom) {
          thisFiltered = [...thisFiltered].filter(
            d => today.diff(d.dateOfBirth, "year") >= (Number(filterFormValues.agefrom) || 0)
          );
        }
        if (filterFormValues.ageto) {
          thisFiltered = [...thisFiltered].filter(
            d => today.diff(d.dateOfBirth, "year") <= (Number(filterFormValues.ageto) || 0)
          );
        }
      }
      if (filterFormValues.gender) {
        thisFiltered = [...thisFiltered].filter(d => d.gender === filterFormValues.gender);
      }
      if (filterFormValues.prefectures) {
        thisFiltered = [...thisFiltered].filter(d =>
          d.prefectures?.toLocaleLowerCase().includes((filterFormValues.prefectures || "")?.toLocaleLowerCase())
        );
      }
      if (filterFormValues.registrationgroup) {
        thisFiltered = [...thisFiltered].filter(d => d.registrationGroup === filterFormValues.registrationgroup);
      }
      setIsFiltered(true);
      setFilteredData(thisFiltered);
    }
  };

  const handleResetFilter = () => {
    setIsFiltered(false);
    setFilteredData([]);
    filterForm.resetFields();
  };

  const renderFilter = () => {
    return (
      <Card bordered={false} className="content-card filter-card">
        <Form form={filterForm} layout="vertical" onFinish={onFilter}>
          <Row gutter={[15, 15]}>
            <Col span={4}>
              <Form.Item name="name" label={t("nameText", { ns: ["main"] })}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="phoneNumber" label={t("phoneNumberText", { ns: ["main"] })}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label={t("ageText", { ns: ["main"] })}>
                <Form.Item name="agefrom" style={{ display: "inline-block", width: "calc(50% - 10px)" }}>
                  <Input />
                </Form.Item>
                <span style={{ display: "inline-block", width: "20px", lineHeight: "40px", textAlign: "center" }}>
                  -
                </span>
                <Form.Item name="ageto" style={{ display: "inline-block", width: "calc(50% - 10px)" }}>
                  <Input />
                </Form.Item>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item label={t("genderText", { ns: ["main"] })} name="gender">
                <Select
                  options={GenderEnum.map(g => {
                    return {
                      value: g.value,
                      label: t(g.key, { ns: ["enum"] }),
                    };
                  })}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item name="prefectures" label={t("prefecturesText", { ns: ["main"] })}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="address" label={t("addressText", { ns: ["main"] })}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="registrationgroup" label={t("registrationGroupText", { ns: ["main"] })}>
                <Select showSearch options={groups} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={2} offset={22} style={{ marginTop: 10, display: "flex", justifyContent: "flex-end" }}>
              <Button htmlType="button" style={{ marginRight: 10 }} onClick={() => handleResetFilter()}>
                {t("resetText", { ns: ["main"] })}
              </Button>
              <Button type="primary" htmlType="submit">
                {t("searchText", { ns: ["main"] })}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    );
  };

  const handleExport = async () => {
    const response = await PhoneBookService.export();
    if (response.status === 200) {
      const filename = response.headers["content-disposition"].split("filename=")[1].split(".")[0];
      const extension = response.headers["content-disposition"].split(".")[1].split(";")[0];
      const str_array = Encoding.stringToCode(response.data);
      const sjis_array = Encoding.convert(str_array, "SJIS", "UNICODE");
      const uint8_array = new Uint8Array(sjis_array);
      const blob = new Blob([uint8_array], {
        type: "text/csv",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${filename}.${extension}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const handleAfterUpload = async () => {
    await fetchData();
  };

  const handleAutoCallRegistration = () => {
    const filterFormValues = getFilterFormData(filterForm.getFieldsValue());
    if (filterFormValues) {
      setInStorage(DEFAULT_REGISTRATION_GROUP_KEY, JSON.stringify({ ...filterFormValues, count: filteredData.length }));
      navigate(URL.AUTO_CALL_ADD);
    } else {
      messageApi.open({
        type: "error",
        content: t("pleaseInputFilter", { ns: ["main"] }) || "Please input at least one filter",
      });
    }
  };

  return (
    <>
      {contextHolder}
      <div className="phone-book-page">
        <div className="app-container">
          <Space direction="vertical" size={25} style={{ display: "flex" }}>
            {renderFilter()}

            <div className="table-container">
              <div className="table-container__header">
                <div>
                  {data.length} {t("casesText", { ns: ["main"] })}
                </div>
                <div className="table-container__header__actions">
                  <Space>
                    <Button onClick={handleExport}>{t("csvExportText", { ns: ["main"] })}</Button>
                    <div title={t("autoCallRegistrationBtnTitle", { ns: ["main"] }) || ""}>
                      <Button onClick={handleAutoCallRegistration} disabled={!isFiltered}>
                        {t("autoCallRegistrationText", { ns: ["main"] })}
                      </Button>
                    </div>
                    <Button onClick={() => navigate(URL.PHONE_BOOK_ADD)} type="primary">
                      {t("addNewText", { ns: ["main"] })}
                    </Button>
                    <Button type="primary" onClick={() => setIsBulkUploadModalOpen(true)}>
                      {t("addBulkText", { ns: ["main"] })}
                    </Button>
                  </Space>
                </div>
              </div>

              <Table
                columns={columns}
                dataSource={isFiltered ? filteredData : data}
                loading={isLoading}
                locale={{ emptyText: t("noData", { ns: ["page"] }) }}
              />
            </div>
          </Space>
        </div>
      </div>
      <BulkUploadModal
        isBulkUploadModalOpen={isBulkUploadModalOpen}
        closeModal={() => setIsBulkUploadModalOpen(false)}
        onSubmit={handleAfterUpload}
      />
    </>
  );
};

export default PhoneBook;
