import React, { useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

const useDocumentTitle = (title: string, retainOnUnmount = false) => {
  const { t } = useTranslation(["auth", "main"]);
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    let metaTitle = "";
    if (title) {
      metaTitle += `${title} - `;
    }
    metaTitle += t("appName", { ns: ["main"] }) || "IVR APP";

    document.title = metaTitle;
  }, [title]);

  useEffect(() => {
    return () => {
      if (!retainOnUnmount) {
        document.title = defaultTitle.current;
      }
    };
  }, []);
};

export default useDocumentTitle;
