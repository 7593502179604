import API from "./axios";

class AudioService {
  getAudioFromUrl(url: string) {
    return API.get(`api/Audios/download?AudioUrl=${url}`, {
      responseType: "blob",
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  uploadAudio = (audioFile: File) => {
    return API.post("/api/Audios", { audioFile: audioFile }, { headers: { "Content-Type": "multipart/form-data" } })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };
}

export default new AudioService();
