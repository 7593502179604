import API from "./axios";
import {
  IForgotPasswordValues,
  ILoginValues,
  ILoginResponseValues,
  IRegisterValues,
  IResetPasswordValues,
} from "../interfaces/auth";

import { getFromStorage, setInStorage, clearStorage } from "../utils/utility";
import { DEFAULT_USER_AUTH_KEY } from "../utils/constants";

class AuthService {
  getSettings() {
    return API.get(`/api/Accounts/settings`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  login(values: ILoginValues) {
    return API.post("/api/Accounts/login", values)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  register(values: IRegisterValues) {
    return API.post("/api/Accounts/signup", values)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  forgotPassword(values: IForgotPasswordValues) {
    return API.post("/api/Accounts/request-reset-password", values)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  resetPassword(values: IResetPasswordValues) {
    return API.post("/api/Accounts/reset-password", values)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  getMfaProviders(bearerToken: string) {
    return API.get(`/api/Accounts/mfa-providers`, {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  verifyMfaToken({ token, mfaToken }: { token: string; mfaToken: string }) {
    return API.post(
      `/api/Accounts/verify-mfa-token`,
      { token },
      {
        headers: {
          Authorization: `Bearer ${mfaToken}`,
        },
      }
    )
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }

  resendMfaToken({ mfaProvider, mfaToken }: { mfaProvider: 1 | 2; mfaToken: string }) {
    return API.post(
      `/api/Accounts/resend-mfa-token`,
      { mfaProvider },
      {
        headers: {
          Authorization: `Bearer ${mfaToken}`,
        },
      }
    )
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }

  activateAccount(values: IResetPasswordValues) {
    return API.post("/api/Accounts/activate", values)
      .then(response => {
        if (response.data.token) {
          setInStorage(DEFAULT_USER_AUTH_KEY, JSON.stringify(response.data));
        }
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  enableMfa(defaultMfaProvider: string) {
    return API.post(`/api/Accounts/enable-mfa`, {
      defaultMfaProvider,
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  disableMfa() {
    return API.post(`/api/Accounts/disable-mfa`, {})
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  requestPhoneNumVerificationCode(phoneNumber: string) {
    return API.post(`/api/Accounts/request-phonenumber-verification-code`, { phoneNumber })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  verifyPhoneNumber({ phoneNumber, token }: { phoneNumber: string; token: string }) {
    return API.post(`/api/Accounts/verify-phonenumber`, { phoneNumber, token })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  removePhoneNumber(phoneNumber: string) {
    return API.delete(`/api/Accounts/remove-phonenumber/${phoneNumber}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  logout() {
    clearStorage();
  }

  getCurrentUser() {
    const user = getFromStorage(DEFAULT_USER_AUTH_KEY);
    if (user) {
      const auth: ILoginResponseValues = JSON.parse(user);
      if (auth.token) {
        return auth;
      }
    }
    return null;
  }
}

export default new AuthService();
