import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import _ from "lodash";
import dayjs from "dayjs";

import { Alert, Button, Card, DatePicker, Form, Input, Radio, Space, Select } from "antd";

import Loading from "../../components/Loading";

import PhoneBookService from "../../services/phoneBook.service";
import { IPhoneBookForm, IPhoneBookCountryCodeOptions } from "../../interfaces/phoneBook";

import { URL } from "../../utils/constants";
import { GenderEnum } from "../../utils/enums";
import { IPageProps } from "../../interfaces/common";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { ArrowLeftOutlined } from "@ant-design/icons";

const PhoneBookForm: FC<IPageProps> = (props: IPageProps) => {
  const { id } = useParams();
  const { t } = useTranslation(["page", "enum", "main", "form"]);
  useDocumentTitle(t(id ? "editPhoneBook" : "addPhoneBook", { ns: ["page"] }) || props.pageMetaTitle);

  const nameText = t("nameText", { ns: ["main"] });
  const countryCodeText = t("countryCodeText", { ns: ["main"] });
  const dateOfBirthText = t("dateOfBirthText", { ns: ["main"] });
  const phoneNumberText = t("phoneNumberText", { ns: ["main"] });
  const genderText = t("genderText", { ns: ["main"] });
  const addressText = t("addressText", { ns: ["main"] });
  const other1Text = t("other1Text", { ns: ["main"] });
  const other2Text = t("other3Text", { ns: ["main"] });
  const other3Text = t("other1Text", { ns: ["main"] });
  const registrationGroupText = t("registrationGroupText", { ns: ["main"] });

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState<boolean>(id ? true : false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [countryCodes, setCountryCodes] = useState<IPhoneBookCountryCodeOptions[]>([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      // await fetchCountryCode();
      if (id) {
        await fetchData(Number(id));
      } else {
        form.setFieldValue("type", "1");
        form.setFieldValue("countryCode", "+81");
      }
      setIsLoading(false);
    })();
  }, [id]);

  const fetchCountryCode = async () => {
    const response = await PhoneBookService.getCountryCodes();
    if (response?.status === 200) {
      setCountryCodes(response.data);
    }
  };

  const fetchData = async (pId: number) => {
    const response = await PhoneBookService.getById(pId);
    if (response?.status === 200) {
      const thisData = {
        ...response.data,
        gender: response.data.gender || null,
        dateOfBirth: response.data.dateOfBirth ? dayjs(response.data.dateOfBirth, "YYYY/MM/DD") : "",
        countryCode: "+81",
      };
      form.setFieldsValue(thisData);
    }
  };

  if (isLoading) {
    return (
      <div className="questionnaire-form-page">
        <div className="app-container">
          <Loading message={t("fetchingDataText", { ns: ["main"] })} />
        </div>
      </div>
    );
  }

  const handleSubmit = async (values: IPhoneBookForm) => {
    setIsSubmitting(true);
    let response;
    values.dateOfBirth = values.dateOfBirth || null;
    if (id) {
      response = await PhoneBookService.update(Number(id), values);
    } else {
      response = await PhoneBookService.add(values);
    }
    if (response?.status === 200) {
      navigate(URL.PHONE_BOOK);
    } else {
      setError(response.data);
    }

    setIsSubmitting(false);
  };

  return (
    <div className="questionnaire-form-page">
      <div className="app-container">
        <h1>
          <ArrowLeftOutlined onClick={() => navigate(URL.PHONE_BOOK)} className="btn-back" />
          {t(id ? "editPhoneBook" : "addPhoneBook", { ns: ["page"] })}
        </h1>
        {error && <Alert message={error} type="error" showIcon closable />}
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Space direction="vertical" size="middle" style={{ display: "flex", width: "100%" }}>
            <Card>
              <Form.Item
                label={nameText}
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("inputRequired", { inputName: nameText, ns: ["form"] }) || "Please input name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="type" label={t("phoneTypeText", { ns: ["main"] })} required>
                <Radio.Group>
                  <Radio value={1}> {t("landlineText", { ns: ["main"] })} </Radio>
                  <Radio value={2}> {t("mobileText", { ns: ["main"] })} </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label={countryCodeText}
                name="countryCode"
                rules={[
                  {
                    required: true,
                    message:
                      t("inputRequired", { inputName: countryCodeText, ns: ["form"] }) || "Please select country code!",
                  },
                ]}
                style={{ display: "none" }}
              >
                <Input readOnly />
                {/* <Select
                  placeholder={t("selectCountryCodeText", { ns: ["form"] })}
                  options={countryCodes.map(c => ({ label: `${c.name} - ${c.code}`, value: c.code }))}
                /> */}
              </Form.Item>
              <Form.Item
                label={phoneNumberText}
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: t("inputRequired", { inputName: phoneNumberText, ns: ["form"] }) || "Please input phone!",
                  },
                ]}
              >
                <Input addonBefore="+81" />
              </Form.Item>
              <Form.Item label={dateOfBirthText} name="dateOfBirth">
                <DatePicker format="YYYY-MM-DD" />
              </Form.Item>
              <Form.Item label={genderText} name="gender">
                <Select
                  options={GenderEnum.map(g => {
                    return {
                      value: g.value,
                      label: t(g.key, { ns: ["enum"] }),
                    };
                  })}
                />
              </Form.Item>
              <Form.Item label={addressText} name="address">
                <Input />
              </Form.Item>
              <Form.Item label={other1Text} name="other1">
                <Input />
              </Form.Item>
              <Form.Item label={other2Text} name="other2">
                <Input />
              </Form.Item>
              <Form.Item label={other3Text} name="other3">
                <Input />
              </Form.Item>
              <Form.Item
                label={registrationGroupText}
                name="registrationGroup"
                rules={[
                  {
                    required: true,
                    message:
                      t("inputRequired", { inputName: registrationGroupText, ns: ["form"] }) ||
                      "Please input registration group!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Card>
            <div className="ant-form-item form-actions">
              <Button htmlType="reset" disabled={isSubmitting}>
                {t("resetText", { ns: ["main"] })}
              </Button>
              <Button type="primary" htmlType="submit" loading={isSubmitting}>
                {isSubmitting ? t("submittingText", { ns: ["form"] }) : t("submitText", { ns: ["form"] })}
              </Button>
            </div>
          </Space>
        </Form>
      </div>
    </div>
  );
};

export default PhoneBookForm;
