import axios from "axios";

import { API_PREFIX, DEFAULT_USER_AUTH_KEY, URL } from "../utils/constants";
import { clearStorage, getFromStorage, getDefaultLanguage } from "../utils/utility";

const API = axios.create({
  // .. where we make our configurations
  baseURL: API_PREFIX,
  timeout: 60000, // Now all requests using this instance will wait 60 seconds before timing out
});

API.defaults.headers.get["Accept"] = "application/json";

API.defaults.headers.post["Accept"] = "application/json";
API.defaults.headers.post["Content-Type"] = "application/json";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
API.interceptors.request.use((config: any) => {
  const currentLanguage = getDefaultLanguage()?.key;
  config.headers.culture = currentLanguage === "en" ? "en-US" : "ja-JP";

  const user = getFromStorage(DEFAULT_USER_AUTH_KEY);
  if (user && config) {
    const auth = JSON.parse(user);
    config.headers.Authorization = `Bearer ${auth.token}`;
  }
  return config;
});

API.interceptors.response.use(
  response => responseSuccessHandler(response),
  error => responseErrorHandler(error)
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const responseSuccessHandler = (response: any) => {
  return response;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const responseErrorHandler = (error: any) => {
  // eslint-disable-next-line no-console
  console.log("<>err<>", error.response.status);

  if (error.response.status === 401) {
    const url = URL.LOGIN;
    clearStorage();
    return window.location.replace(url);
  }

  return Promise.reject(error);
};

export default API;
