import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import Layout from "./components/Layout";

import ReportService from "./services/report.service";

import useAuth from "./hooks/useAuth";
import { URL } from "./utils/constants";
import { ILocation } from "./interfaces/common";
import { UsageContext } from "./context/usageContext";

const PrivateOutlet = () => {
  const currentUser = useAuth();
  const location: ILocation = useLocation();

  const [usage, setUsage] = useState(undefined);

  useEffect(() => {
    if (currentUser) {
      (async () => {
        const response = await ReportService.getUsage();
        if (response?.status === 200) {
          setUsage(response.data);
        }
      })();
    }
  }, []);

  if (!currentUser) {
    return <Navigate to={URL.LOGIN} state={JSON.stringify({ from: location.pathname })} replace />;
  }

  return (
    <UsageContext.Provider value={{ usage, setUsage }}>
      <Layout>
        <Outlet />
      </Layout>
    </UsageContext.Provider>
  );
};

export default PrivateOutlet;
