import { FC } from "react";

import { useTranslation } from "react-i18next";

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import "./style.scss";

interface ILoading {
  message: string;
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Loading: FC<ILoading> = ({ message }: ILoading) => {
  const { t } = useTranslation(["main"]);
  return (
    <div className="loading-wrapper" role="loading">
      <h5 className="text-primary">
        <Spin indicator={antIcon} /> {t("pleaseWait", { ns: ["main"] })}...
      </h5>
      <div className="loading-message">{message}</div>
    </div>
  );
};

export default Loading;
