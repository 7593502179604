import { FC, useState } from "react";

import _ from "lodash";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { useTranslation } from "react-i18next";

import { InfoCircleFilled } from "@ant-design/icons";
import { Alert, Button, Modal } from "antd";

import Encoding from "encoding-japanese";

import PhoneBookService from "../../services/phoneBook.service";
import { IPhoneBookBulkUploadFormData, IPhoneBookBulkUploadPhoneBooks } from "../../interfaces/phoneBook";

interface IBulkUploadModal {
  isBulkUploadModalOpen: boolean;
  closeModal: () => void;
  onSubmit: () => void;
}

dayjs.extend(utc);

const BulkUploadModal: FC<IBulkUploadModal> = ({ isBulkUploadModalOpen, closeModal, onSubmit }: IBulkUploadModal) => {
  const { t } = useTranslation(["form", "main"]);

  const [error, setError] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleCloseModal = () => {
    closeModal();
  };

  const csvFileToArray = (string: string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    return csvRows.map((i: string) => {
      const values = i.split(",");
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const obj = csvHeader.reduce((object: any, header: string, index: number) => {
        const key = _.camelCase(header);
        let value = _.trimStart(values[index], `"`);
        value = _.trimEnd(value, `"`);
        object[key] = value;
        return object;
      }, {});
      return obj;
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setError("");
    setIsSubmitting(true);
    interface IFormElem {
      registration_group: { value: string };
      file: HTMLFormElement;
    }

    event.preventDefault();
    const input = event.target as typeof event.target & IFormElem;
    const registrationGroup = input.registration_group.value;
    const files = input.file.files[0];
    if (!registrationGroup) {
      setError(t("inputRegistrationGroupText", { ns: ["form"] }) || "Please Input Registration Group");
      setIsSubmitting(false);
    }
    if (!files) {
      setError(t("selectFileText", { ns: ["form"] }) || "Please Select File");
      setIsSubmitting(false);
    }

    const fileReader = new FileReader();
    fileReader.onload = async e => {
      const codes = new Uint8Array(e.target?.result as ArrayBuffer);
      const detectedEncoding = Encoding.detect(codes);
      const unicodeString = Encoding.convert(codes, {
        to: "UNICODE",
        from: detectedEncoding || "SJIS",
        type: "string",
      });
      const csvArr = csvFileToArray(unicodeString);
      const cleanedCsvArr: IPhoneBookBulkUploadPhoneBooks[] = csvArr
        .filter(data => data.name)
        .map(data => {
          let gender = 0;
          if (data.gender === "Male" || data.gender === "男性") {
            gender = 1;
          } else if (data.gender === "Female" || data.gender === "女性") {
            gender = 2;
          }
          return {
            ...data,
            dateOfBirth: data.dateOfBirth ? dayjs(data.dateOfBirth).utc(true).format() : null,
            gender: gender,
            type: _.lowerCase(data.type) === "mobile" ? 2 : 1,
            countryCode: data.countryCode || "+81",
          };
        });
      const formData: IPhoneBookBulkUploadFormData = {
        registrationGroup: registrationGroup,
        phonebooks: cleanedCsvArr,
      };
      const response = await PhoneBookService.import(formData);
      if (response?.status === 200) {
        onSubmit();
        handleCloseModal();
      } else {
        setError(t("formErrorText", { ns: ["form"] }) || "Error in submitting form");
      }

      setIsSubmitting(false);
    };

    fileReader.readAsArrayBuffer(files);
  };

  return (
    <Modal
      centered
      footer={null}
      onCancel={handleCloseModal}
      open={isBulkUploadModalOpen}
      title={t("uploadBulkPhoneBookText", { ns: ["main"] })}
      className="phone-book-bulk-upload-modal"
    >
      <form className="ant-form ant-form-vertical" onSubmit={handleSubmit}>
        {error && <Alert message={error} type="error" showIcon closable />}
        <div className="ant-form-item">
          <div className="ant-row ant-form-item-row">
            <div className="ant-col ant-form-item-label">
              <label htmlFor="registrationGroup" className="ant-form-item-required" title="Registration Group">
                {t("registrationGroupText", { ns: ["main"] })}
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content">
                  <input
                    name="registration_group"
                    id="registrationGroup"
                    aria-required="true"
                    className="ant-input"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ant-form-item">
          <div className="ant-row ant-form-item-row">
            <div className="ant-col ant-form-item-label">
              <label htmlFor="file" className="ant-form-item-required" title="File">
                {t("fileText", { ns: ["main"] })}
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content">
                  <input type="file" name="file" id="file" aria-required="true" accept=".csv" required />
                  <a href="./sample/phonebook-sample.csv" style={{ display: "block", marginTop: 10, fontSize: 12 }}>
                    <InfoCircleFilled /> {t("downloadSampleFileText", { ns: ["main"] })}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ant-space-item">
          <div className="ant-form-item form-actions">
            <Button htmlType="reset" disabled={isSubmitting} onClick={handleCloseModal}>
              {t("cancelText", { ns: ["form"] })}
            </Button>
            <Button htmlType="submit" type="primary" loading={isSubmitting}>
              {isSubmitting ? t("submittingText", { ns: ["form"] }) : t("submitText", { ns: ["form"] })}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default BulkUploadModal;
