import API from "./axios";

import { IAutoCallForm } from "../interfaces/autoCall";
import { jsonToFormData } from "../utils/utility";

class AutoCallService {
  get = () => {
    return API.get("/api/AutoCalls")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  getById = (id: number) => {
    return API.get(`/api/AutoCalls/${id}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  clone = (id: number) => {
    return API.post(`/api/AutoCalls/${id}/clone`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  add = (data: IAutoCallForm) => {
    const formData = jsonToFormData(data);
    return API.post("/api/AutoCalls", formData, { headers: { "Content-Type": "multipart/form-data" } })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  update = (id: number, data: IAutoCallForm) => {
    const formData = jsonToFormData(data);
    return API.put(`/api/AutoCalls/${id}`, formData, { headers: { "Content-Type": "multipart/form-data" } })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  duplicate = (id: number) => {
    return API.post(`/api/AutoCalls/${id}/clone`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  delete = (id: number) => {
    return API.delete(`/api/AutoCalls/${id}`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  getResultOutput = (id: number) => {
    return API.get(`/api/AutoCalls/${id}/result-output`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  getAnalysis = (id: number) => {
    return API.get(`/api/AutoCalls/${id}/analysis`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };

  getProjectedCost = (surveyId: string, registrationGroup: { [key: string]: string }) => {
    return API.post(`/api/AutoCalls/projected-cost?surveyId=${surveyId}`, { ...registrationGroup })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  };
}

export default new AutoCallService();
