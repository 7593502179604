import { Link } from "react-router-dom";

import PAGES from "./pages";
import { MY_STORAGE, LANGUAGES, DEFAULT_USER_AUTH_KEY, DEFAULT_LANGUAGE_KEY } from "./constants";
import { IQuestionnaire } from "../interfaces/survey";
import { ILoginResponseValues } from "../interfaces/auth";

export const setInStorage = (key: string, value: string) => {
  MY_STORAGE.setItem(key, value);
};

export const getFromStorage = (key: string) => {
  return MY_STORAGE.getItem(key);
};

export const removeFromStorage = (key: string) => {
  return MY_STORAGE.removeItem(key);
};

export const clearStorage = () => {
  return MY_STORAGE.clear();
};

export const setUser = (userData: ILoginResponseValues): void => {
  setInStorage(
    DEFAULT_USER_AUTH_KEY,
    JSON.stringify({
      firstName: userData.firstName,
      lastName: userData.lastName,
      tokenType: userData.tokenType, // 1: mfa, 2: jwt
      token: userData.token,
      twilioNumber: userData.twilioNumber,
    })
  );
};

export const getDefaultLanguage = () => {
  const languageFromStorage = localStorage.getItem(DEFAULT_LANGUAGE_KEY);
  if (languageFromStorage) {
    return LANGUAGES.find(l => l.key === languageFromStorage);
  }
  return LANGUAGES.find(l => l.default);
};

export const setDefaultLanguage = (key: string) => {
  return localStorage.setItem(DEFAULT_LANGUAGE_KEY, key);
};

export const getMenuItems = () => {
  const logout = () => {
    clearStorage();
    location.reload();
  };

  const menuItems = PAGES.filter(page => !page.subPage).map(page => ({
    label: <Link to={page.link}>{page.name}</Link>,
    key: page.link,
  }));
  menuItems.push({
    label: <div onClick={() => logout()}>Logout</div>,
    key: "logout",
  });

  return menuItems;
};

export const getUrlParams = (key: string) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(key);
};

export const getInitials = (fullName: string | undefined) => {
  if (!fullName) {
    return "";
  }

  const allNames = fullName.trim().split(" ");
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`;
    }
    return acc;
  }, "");

  return initials;
};

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return phoneNumberString;
};

export const validatePhoneNumber = (phoneNumberString: string): boolean => {
  if (phoneNumberString) {
    const check = phoneNumberString.match(/\d/g);
    if (check && check.length === 10) {
      return true;
    }
  }

  return false;
};

export const formatCurrency = (number: number | string, currency = "JPY") => {
  if (!number || isNaN(Number(number))) number = 0;

  return new Intl.NumberFormat("ja-JP", {
    style: "decimal",
    currency: currency,
  }).format(Number(number));
};

export const textToSpeech = (text: string, language = "en") => {
  const synth = window.speechSynthesis;
  function getVoices() {
    let voices = synth.getVoices();
    if (!voices.length) {
      const utterance = new SpeechSynthesisUtterance("");
      synth.speak(utterance);
      voices = synth.getVoices();
    }
    return voices;
  }

  const speakData = new SpeechSynthesisUtterance();
  speakData.volume = 1; // From 0 to 1
  speakData.rate = 0.9; // From 0.1 to 10
  speakData.pitch = 1; // From 0 to 2
  speakData.lang = language;
  speakData.voice = getVoices()[0];

  speakData.text = text;
  synth.speak(speakData);
};

export const flattenArr = (arr: IQuestionnaire[]) => {
  const result: IQuestionnaire[] = [];
  arr.forEach((item: IQuestionnaire) => {
    const { id, tempId, questionnaires, ...data } = item;
    if (item.id) {
      result.push({ id, ...data });
    } else {
      result.push({ tempId, ...data });
    }
    if (questionnaires) result.push(...flattenArr(questionnaires));
  });
  return result;
};

export const toTree = (data: IQuestionnaire[], pid: number | null = null, removeTempId = false) => {
  return data.reduce((r: IQuestionnaire[], e: IQuestionnaire) => {
    if (e.parentQuestionId == pid) {
      const obj = { ...e };
      const id = e.id || e.tempId;
      const children = toTree(data, id, removeTempId);
      if (children.length) obj.questionnaires = children;
      if (removeTempId || !obj.tempId) {
        delete obj.tempId;
      }
      if (!obj.id) {
        delete obj.id;
      }
      r.push(obj);
    }
    return r;
  }, []);
};

export const constructEmptyQuestionnaire = (parentQuestionId: number, dependentOption: number) => {
  return {
    tempId: Date.now(),
    title: "",
    content: "",
    type: 1,
    optionType: 2,
    dependentOption: dependentOption,
    parentQuestionId: parentQuestionId,
    optionList: [],
    questionnaires: [],
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const jsonToFormData = (data: any) => {
  const fData = new FormData();

  for (const key in data) {
    const value = data[key];
    if (key === "phonebookSearchList") {
      const searchList = data[key];
      for (const k in searchList) {
        fData.append(`phonebookSearchList[${k}]`, searchList[k]);
      }
    } else if (key === "surveyIds") {
      const ids = data[key];
      for (const k in ids) {
        fData.append(`SurveyIds[]`, ids[k]);
      }
    } else {
      fData.append(key, value);
    }
  }

  return fData;
};

export const createFileFromUrl = async (url: string) => {
  if (url) {
    const name = url.substring(url.lastIndexOf("/") + 1);
    const response = await fetch(url, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
    const data = await response.blob();
    const file = new File([data], name);

    return file;
  }
};
