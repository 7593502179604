import { FC, useEffect, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { Alert, Button, Divider, Form, Input, Typography } from "antd";

import SelectLanguage from "../../components/SelectLanguage";

import useAuth from "../../hooks/useAuth";
import { URL } from "../../utils/constants";
import { ILocation } from "../../interfaces/common";
import { IForgotPasswordValues } from "../../interfaces/auth";
import AuthService from "../../services/auth.service";
import useDocumentTitle from "../../hooks/useDocumentTitle";

const ForgotPassword: FC = () => {
  const currentUser = useAuth();
  const location: ILocation = useLocation();

  const { t } = useTranslation(["auth", "form", "main"]);
  useDocumentTitle(t("forgotPassword"));

  const emailText = t("email", { ns: ["auth"] });

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);

  useEffect(() => {
    // if the user is already logged in, no need to do it again
    if (currentUser) {
      setRedirectToReferrer(true);
    }
  }, [currentUser]);

  if (redirectToReferrer) {
    let redirectUrl = null;
    if (typeof location.state == "string") {
      const state = JSON.parse(location.state);
      redirectUrl = state?.from;
    }

    return <Navigate to={redirectUrl || URL.DASHBOARD} replace />;
  }

  const onFinish = async (values: IForgotPasswordValues) => {
    setSuccess("");
    setLoading(true);

    await AuthService.forgotPassword(values);

    setSuccess(t("resetPasswordEmailText", { ns: ["auth"] }) || "Please check your email to reset password.");
    setLoading(false);

    form.resetFields();
  };

  const renderForm = () => {
    return (
      <Form form={form} name="login_form" layout="vertical" onFinish={onFinish} autoComplete="off">
        {success && <Alert message={success} type="success" showIcon />}

        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: t("inputRequired", { inputName: emailText, ns: ["form"] }) || "Please input your Email!",
            },
            {
              type: "email",
              message: t("inputInvalid", { inputName: emailText, ns: ["form"] }) || "Invalid Email",
            },
            {
              max: 50,
              message:
                t("inputLimit", { inputName: emailText, limit: "11", ns: ["form"] }) ||
                "Email should not exceed 50 characters",
            },
          ]}
        >
          <Input type="email" disabled={loading} placeholder={emailText} autoComplete="username" />
        </Form.Item>

        <Form.Item className="form-actions">
          <Button type="primary" htmlType="submit" className="login-form-button" loading={loading} block>
            {loading ? t("pleaseWait", { ns: ["main"] }) : t("resetPassword", { ns: ["auth"] })}
          </Button>
        </Form.Item>

        <div className="form-register">
          <Divider></Divider>
          <div>
            {t("notMember", { ns: ["auth"] })}? <Link to={URL.REGISTER}>{t("signUp", { ns: ["auth"] })}</Link>
          </div>
          <div style={{ marginTop: 15 }}>
            {t("alreadyHaveAccount", { ns: ["auth"] })}? <Link to={URL.LOGIN}>{t("login", { ns: ["auth"] })}</Link>
          </div>
        </div>
      </Form>
    );
  };

  return (
    <div className="login-page" id="loginPage">
      <div className="login-form">
        <div className="login-form-header">
          <Typography.Title>{t("forgotPassword", { ns: ["auth"] })}</Typography.Title>
          <Typography.Title level={3}>{t("forgotPasswordText", { ns: ["auth"] })}</Typography.Title>
        </div>

        {renderForm()}

        <div className="select-language-container">
          <SelectLanguage popupContainerElem={document.getElementById("loginPage")} />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
