import { FC, useEffect, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import _ from "lodash";

import { Trans, useTranslation } from "react-i18next";

import { Alert, Button, Checkbox, Divider, Form, Input, Result, Typography } from "antd";

import SelectLanguage from "../../components/SelectLanguage";

import useAuth from "../../hooks/useAuth";
import { URL } from "../../utils/constants";
import { ILocation } from "../../interfaces/common";
import AuthService from "../../services/auth.service";
import { IRegisterValues } from "../../interfaces/auth";
import useDocumentTitle from "../../hooks/useDocumentTitle";

const Register: FC = () => {
  const currentUser = useAuth();
  const location: ILocation = useLocation();

  const { t, i18n } = useTranslation(["auth", "form", "main"]);
  useDocumentTitle(t("register"));

  const firstNameText = t("firstName", { ns: ["form"] });
  const lastNameText = t("lastName", { ns: ["form"] });
  const emailText = t("email", { ns: ["form"] });
  const phoneNumberText = t("phoneNumber", { ns: ["form"] });

  const firstNameRules = [
    {
      required: true,
      message: t("inputRequired", { inputName: firstNameText, ns: ["form"] }) || "Please input your First Name!",
      whitespace: true,
    },
    {
      max: 50,
      message:
        t("inputLimit", { inputName: firstNameText, limit: "50", ns: ["form"] }) ||
        "Name should not exceed 50 characters",
    },
  ];

  const lastNameRules = [
    {
      required: true,
      message: t("inputRequired", { inputName: lastNameText, ns: ["form"] }) || "Please input your Family Name!",
      whitespace: true,
    },
    {
      max: 50,
      message:
        t("inputLimit", { inputName: lastNameText, limit: "50", ns: ["form"] }) ||
        "Name should not exceed 50 characters",
    },
  ];

  const phoneNumberRules = [
    {
      required: true,
      message: t("inputRequired", { inputName: phoneNumberText, ns: ["form"] }) || "Please input your phone number!",
      whitespace: true,
    },
    {
      max: 11,
      message:
        t("inputLimit", { inputName: phoneNumberText, limit: "11", ns: ["form"] }) ||
        "Name should not exceed 11 characters",
    },
  ];

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [isSignedUp, setIsSignedUp] = useState(false);

  useEffect(() => {
    // if the user is already logged in, no need to do it again
    if (currentUser) {
      setRedirectToReferrer(true);
    }
  }, [currentUser]);

  if (redirectToReferrer) {
    let redirectUrl = null;
    if (typeof location.state == "string") {
      const state = JSON.parse(location.state);
      redirectUrl = state?.from;
    }

    return <Navigate to={redirectUrl || URL.DASHBOARD} replace />;
  }

  const onFinish = async (values: IRegisterValues) => {
    setError("");
    setSuccess("");
    setLoading(true);

    const response = await AuthService.register(values);
    if (response.status === 200) {
      setIsSignedUp(true);
      form.resetFields();
    } else {
      let errMessage = t("formErrorText", { ns: ["form"] }) || "Error in submitting form";
      if (response?.data) {
        if (!response.data.includes("[")) {
          errMessage = response.data;
        } else {
          const dataParse = JSON.parse(response.data);
          const errors = dataParse ? Object.values(dataParse) : null;
          if (errors?.length) {
            const firstErrMessage = errors[0];
            if (_.isArray(firstErrMessage) && firstErrMessage?.length) {
              errMessage = firstErrMessage[0];
            } else if (typeof firstErrMessage === "string") {
              errMessage = firstErrMessage;
            }
          }
        }
      }
      setError(errMessage);
    }
    setLoading(false);
  };

  const renderForm = () => {
    return (
      <Form form={form} name="login_form" layout="vertical" onFinish={onFinish} autoComplete="off">
        {error && <Alert message={error} type="error" showIcon />}
        {success && <Alert message={success} type="success" showIcon />}

        <Form.Item name="lastName" label={lastNameText} rules={lastNameRules}>
          <Input />
        </Form.Item>

        <Form.Item name="firstName" label={firstNameText} rules={firstNameRules}>
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label={emailText}
          rules={[
            {
              required: true,
              message: t("inputRequired", { inputName: emailText, ns: ["form"] }) || "Please input your E-mail!",
            },
            {
              type: "email",
              message: t("inputInvalid", { inputName: emailText, ns: ["form"] }) || "Invalid E-mail",
            },
            {
              max: 50,
              message:
                t("inputLimit", { inputName: emailText, limit: "50", ns: ["form"] }) ||
                "E-mail should not exceed 50 characters",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="phoneNumber" label={phoneNumberText} rules={phoneNumberRules}>
          <Input />
        </Form.Item>

        <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (__, value) =>
                value ? Promise.resolve() : Promise.reject(new Error(t("agreementAcceptText", { ns: ["auth"] }) || "")),
            },
          ]}
        >
          <Checkbox>
            <Trans ns={["auth"]} i18nKey="agreementText">
              I have read the <Link to="/">agreement</Link>
            </Trans>
          </Checkbox>
        </Form.Item>

        <Form.Item className="form-actions">
          <Button type="primary" htmlType="submit" className="register-form-button" loading={loading} block>
            {loading ? t("pleaseWait", { ns: ["main"] }) : t("register", { ns: ["auth"] })}
          </Button>
        </Form.Item>

        <div className="form-register">
          <Divider>{t("or", { ns: ["form"] })}</Divider>
          {t("alreadyHaveAccount", { ns: ["auth"] })}? <Link to={URL.LOGIN}>{t("login", { ns: ["auth"] })}</Link>
        </div>
      </Form>
    );
  };

  if (isSignedUp) {
    return (
      <div className="login-page" id="loginPage">
        <div className="login-form">
          <Result
            status="success"
            title={t("accountCreatedText", { ns: ["auth"] })}
            subTitle={t("activateEmailText", { ns: ["auth"] })}
            extra={[
              <Link key="login" to={URL.LOGIN} className="ant-btn ant-btn-primary">
                {t("goToLogin", { ns: ["auth"] })}
              </Link>,
            ]}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="login-page" id="loginPage">
      <div className="login-form">
        <div className="login-form-header">
          <Typography.Title>{t("register", { ns: ["auth"] })}</Typography.Title>
        </div>

        {renderForm()}

        <div className="select-language-container">
          <SelectLanguage popupContainerElem={document.getElementById("loginPage")} />
        </div>
      </div>
    </div>
  );
};

export default Register;
