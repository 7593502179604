import { FC } from "react";
import { useRoutes } from "react-router-dom";

import PAGES from "./utils/pages";
import { IPage } from "./interfaces/common";

import Auth from "./pages/Auth";
import Mfa from "./pages/Auth/mfa";
import Activate from "./pages/Auth/active";
import Register from "./pages/Auth/register";
import NoAccess from "./pages/NotFound/no-access";
import ResetPassword from "./pages/Auth/reset-password";
import ForgotPassword from "./pages/Auth/forgot-password";

import NotFound from "./pages/NotFound";
import PrivateOutlet from "./PrivateOutlet";

import { URL } from "./utils/constants";

const MyRoutes: FC = () => {
  const pages = PAGES.map((p: IPage) => ({
    path: p.link,
    element: p.element,
  }));

  return useRoutes([
    { path: URL.MFA, element: <Mfa /> },
    { path: URL.LOGIN, element: <Auth /> },
    { path: URL.REGISTER, element: <Register /> },
    { path: URL.NO_ACCESS, element: <NoAccess /> },
    { path: URL.ACTIVATE_ACCOUNT, element: <Activate /> },
    { path: URL.RESET_PASSWORD, element: <ResetPassword /> },
    { path: URL.FORGOT_PASSWORD, element: <ForgotPassword /> },
    {
      path: "/",
      element: <PrivateOutlet />,
      children: pages,
    },
    { path: "*", element: <NotFound /> },
  ]);
};

export default MyRoutes;
