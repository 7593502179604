export const API_PREFIX = process.env.REACT_APP_API_BASE_URL;
export const MY_STORAGE = window.sessionStorage;

export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";

export const DEFAULT_USER_AUTH_KEY = "ivrClientUser";
export const DEFAULT_LANGUAGE_KEY = "ivrClientLanguage";
export const DEFAULT_REGISTRATION_GROUP_KEY = "ivrClientRegistrationGroups";

export const REGEX_LOWER_CASE = /[a-z]+/;
export const REGEX_UPPER_CASE = /[A-Z]+/;
export const REGEX_DIGI_CASE = /[0-9]+/;
export const REGEX_SPECIAL_CASE = /[!@#$%^&*()_=+]+/;

// Urls
export const URL = {
  LOGIN: "/login",
  PAYMENT: "/payment",
  MFA: "/accounts/mfa",
  REGISTER: "/accounts/sign-up",
  ACTIVATE_ACCOUNT: "/accounts/activate",
  RESET_PASSWORD: "/accounts/reset-password",
  FORGOT_PASSWORD: "/accounts/forgot-password",
  NO_ACCESS: "/403",

  // authorized urls
  DASHBOARD: "/",
  PHONE_BOOK: "/phone-book",
  PHONE_BOOK_ADD: "/phone-book/add",
  PHONE_BOOK_EDIT: "/phone-book/edit/:id",
  QUESTIONNAIRE: "/questionnaire",
  QUESTIONNAIRE_ADD: "/questionnaire/add",
  QUESTIONNAIRE_EDIT: "/questionnaire/edit/:id",
  AUTO_CALL: "/auto-call",
  AUTO_CALL_ADD: "/auto-call/add",
  AUTO_CALL_EDIT: "/auto-call/edit/:id",

  // accounts
  ACCOUNT_SETTINGS: "/accounts/settings",
};

export const VALIDATE_MESSAGES = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};

export const LANGUAGES = [
  {
    key: "jp",
    label: "日本",
    default: true,
  },
  {
    key: "en",
    label: "English",
    default: false,
  },
];
