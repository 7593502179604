import { FC } from "react";

import { useTranslation } from "react-i18next";

import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space, Typography } from "antd";
import type { MenuProps } from "antd";
import { MenuClickEventHandler } from "rc-menu/lib/interface";

import { DEFAULT_LANGUAGE_KEY, LANGUAGES } from "../../utils/constants";
import { getDefaultLanguage, setDefaultLanguage } from "../../utils/utility";

interface ISelectLanguage {
  popupContainerElem: HTMLElement | null;
}

const SelectLanguage: FC<ISelectLanguage> = ({ popupContainerElem }: ISelectLanguage) => {
  const { i18n, t } = useTranslation(["auth", "form", "main"]);

  const items: MenuProps["items"] = LANGUAGES;
  const languageFromStorage = localStorage.getItem(DEFAULT_LANGUAGE_KEY);
  const currentLanguage = (LANGUAGES.find(l => l.key === i18n.language) || getDefaultLanguage())?.label;

  if (!languageFromStorage) {
    localStorage.setItem(DEFAULT_LANGUAGE_KEY, i18n.language);
  }

  const updateLanguage: MenuClickEventHandler = ({ key }) => {
    window.location.reload();
    i18n.changeLanguage(key);
    setDefaultLanguage(key);
  };

  return (
    <div>
      <Dropdown
        menu={{
          items,
          selectable: true,
          defaultSelectedKeys: [i18n.language],
          onClick: updateLanguage,
        }}
        trigger={["click"]}
        overlayClassName="select-language-dropdown"
        // getPopupContainer={() => popupContainerElem || document.body}
      >
        <Typography.Link>
          <Space>
            {t("selectLanguage", { ns: ["main"] })}: <span className="current-language">{currentLanguage}</span>
            <DownOutlined />
          </Space>
        </Typography.Link>
      </Dropdown>
    </div>
  );
};

export default SelectLanguage;
