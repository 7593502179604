import API from "./axios";

import { ISetupPhoneNumberPayment } from "../interfaces/customer";
import axios from "axios";

class CustomerService {
  getAvailablePhoneNumbers() {
    return API.get("/api/Customers/available-phone-numbers")
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  setUpPhoneNumberPayment(values: ISetupPhoneNumberPayment) {
    return API.post("/api/Customers/setup-phonenumber-payment", values)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  uploadDocument(formData: FormData) {
    return API.post("/api/client-area/Customers/upload-document", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(response => {
        return response;
      })
      .catch(error => {
        return error.response;
      });
  }

  async getFileFromDocName(docName: string) {
    return API.get(`/api/client-area/Customers/documents/${docName}`, { responseType: "arraybuffer" }).then(
      response => {
        if (response) {
          const image = btoa(
            new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), "")
          );

          const contentType = response.headers["content-type"];
          if (contentType) {
            return `data:${contentType.toLowerCase()};base64,${image}`;
          }
        }

        return "";
      }
    );
  }
}

export default new CustomerService();
