import { SettingOutlined } from "@ant-design/icons";

import Dashboard from "../pages/Dashboard";

import PhoneBook from "../pages/PhoneBook";
import PhoneBookForm from "../pages/PhoneBook/form";

import Questionnaire from "../pages/Questionnaire";
import QuestionnaireForm from "../pages/Questionnaire/form";

import AutoCall from "../pages/AutoCall";
import AutoCallForm from "../pages/AutoCall/form";

import AccountSettings from "../pages/Accounts/settings";

import { URL } from "../utils/constants";
import { IPage } from "../interfaces/common";

const PAGES: Array<IPage> = [
  {
    id: 1,
    key: "dashboard",
    name: "Dashboard",
    element: <Dashboard pageTitle="Dashboard" pageMetaTitle="Dashboard" />,
    link: URL.DASHBOARD,
    subPage: false,
  },
  {
    id: 2,
    key: "phoneBook",
    name: "Phone Book",
    element: <PhoneBook pageTitle="Phone Book" pageMetaTitle="Phone Book" />,
    link: URL.PHONE_BOOK,
    subPage: false,
  },
  {
    id: 2.1,
    key: "addPhoneBook",
    name: "Add Phone Book",
    element: <PhoneBookForm pageTitle="Add Phone Book" pageMetaTitle="Add Phone Book" />,
    link: URL.PHONE_BOOK_ADD,
    subPage: true,
  },
  {
    id: 2.2,
    key: "editPhoneBook",
    name: "Edit Phone Book",
    element: <PhoneBookForm pageTitle="Edit Phone Book" pageMetaTitle="Edit Phone Book" />,
    link: URL.PHONE_BOOK_EDIT,
    subPage: true,
  },
  {
    id: 3,
    key: "questionnaire",
    name: "Questionnaire",
    element: <Questionnaire pageTitle="Questionnaire" pageMetaTitle="Questionnaire" />,
    link: URL.QUESTIONNAIRE,
    subPage: false,
  },
  {
    id: 3.1,
    key: "addQuestionnaire",
    name: "Add Questionnaire",
    element: <QuestionnaireForm pageTitle="Add Questionnaire" pageMetaTitle="Add Questionnaire" />,
    link: URL.QUESTIONNAIRE_ADD,
    subPage: true,
  },
  {
    id: 3.2,
    key: "editQuestionnaire",
    name: "Edit Questionnaire",
    element: <QuestionnaireForm pageTitle="Edit Questionnaire" pageMetaTitle="Edit Questionnaire" />,
    link: URL.QUESTIONNAIRE_EDIT,
    subPage: true,
  },
  {
    id: 4,
    key: "autoCall",
    name: "Auto Call",
    element: <AutoCall pageTitle="Auto Call" pageMetaTitle="Auto Call" />,
    link: URL.AUTO_CALL,
    subPage: false,
  },
  {
    id: 4.1,
    key: "addAutoCall",
    name: "Add Auto Call",
    element: <AutoCallForm pageTitle="Add Auto Call" pageMetaTitle="Add Auto Call" />,
    link: URL.AUTO_CALL_ADD,
    subPage: true,
  },
  {
    id: 5.2,
    key: "editAutoCall",
    name: "Edit Auto Call",
    element: <AutoCallForm pageTitle="Edit Auto Call" pageMetaTitle="Edit Auto Call" />,
    link: URL.AUTO_CALL_EDIT,
    subPage: true,
  },
  {
    id: 6,
    key: "settings",
    name: "Settings",
    element: <AccountSettings pageTitle="Account Settings" pageMetaTitle="Account Settings" />,
    link: URL.ACCOUNT_SETTINGS,
    userPage: true,
    icon: <SettingOutlined />,
  },
];

export default PAGES;
