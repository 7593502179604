import { FC, Fragment, useEffect, useState } from "react";

import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Button, Col, Divider, Drawer, Empty, Row, Space } from "antd";

import Loading from "../../components/Loading";

import AutoCallService from "../../services/autoCall.service";
import { AutoCallStatusEnumLog, QuestionnaireTypeEnum } from "../../utils/enums";
import { IAutoCallAnalysis, IAutoCallResultOutput } from "../../interfaces/autoCall";

interface IReportDrawerProps {
  showDrawer: number | undefined;
  setShowDrawer: (id: number | undefined) => void;
}

const ReportDrawer: FC<IReportDrawerProps> = ({ showDrawer, setShowDrawer }) => {
  const { t } = useTranslation(["page", "enum", "main"]);

  const [loading, setLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [analysis, setAnalysis] = useState<IAutoCallAnalysis>();
  const [resultOutput, setResultOutput] = useState<IAutoCallResultOutput[]>([]);

  useEffect(() => {
    if (showDrawer) {
      (async () => {
        setLoading(true);
        await Promise.all([generateResultOutput(showDrawer), generateAnalysis(showDrawer)]);
        setLoading(false);
      })();
    }
  }, [showDrawer]);

  const generateResultOutput = async (id: number) => {
    setLoading(true);
    const response = await AutoCallService.getResultOutput(id);
    if (response?.status === 200) {
      setResultOutput(response.data);
    }
    setLoading(false);
  };

  const generateAnalysis = async (id: number) => {
    setLoading(true);
    const response = await AutoCallService.getAnalysis(id);
    if (response?.status === 200) {
      setAnalysis(response.data);
    }
    setLoading(false);
  };

  const handleCloseDrawer = () => {
    setShowDrawer(undefined);
    setResultOutput([]);
    setAnalysis(undefined);
  };

  const renderAnalysis = () => {
    return (
      <Space direction="vertical">
        <Row gutter={16} className="analysis-drawer__summary">
          <Col xs={24} sm={12} lg={5}>
            <div className="analysis-drawer__summary__item">
              <h2>Total</h2>
              <span>{analysis?.totalCount}</span>
            </div>
          </Col>
          <Col xs={24} sm={12} lg={4}>
            <div className="analysis-drawer__summary__item">
              <h2>Completed</h2>
              <span>{analysis?.completedCount}</span>
            </div>
          </Col>
          <Col xs={24} sm={12} lg={5}>
            <div className="analysis-drawer__summary__item">
              <h2>Not Answered</h2>
              <span>{analysis?.notAnsweredCount}</span>
            </div>
          </Col>
          <Col xs={24} sm={12} lg={4}>
            <div className="analysis-drawer__summary__item">
              <h2>Error</h2>
              <span>{analysis?.errorCount}</span>
            </div>
          </Col>
          <Col xs={24} sm={24} lg={6}>
            <div className="analysis-drawer__summary__item">
              <h2>Total Call Time</h2>
              <span>{analysis?.totalCallTime}</span>
            </div>
          </Col>
        </Row>
      </Space>
    );
  };

  const renderResultOutput = () => {
    const renderResultOutputContent = (result: IAutoCallResultOutput, index: number) => {
      const thisStatus = AutoCallStatusEnumLog.find(g => g.value === result.status)?.key || "notStarted";
      const thisType = QuestionnaireTypeEnum.find(g => g.value === result.autoCallType)?.key || "textToSpeech";

      return (
        <Fragment key={index}>
          <tr className={selectedRow === index ? "app-table__tr-selected" : ""}>
            <td>{dayjs(result.callDate).format("YYYY/MM/DD HH:mm")}</td>
            <td>{result.name}</td>
            <td>{result.phoneNumber}</td>
            <td>{t(thisType, { ns: ["enum"] })}</td>
            <td>{result.totalCallTime}</td>
            <td>{t(thisStatus, { ns: ["enum"] })}</td>
          </tr>
        </Fragment>
      );
    };

    console.log(">>> resultOutput", resultOutput);

    return (
      <table className="app-table">
        <thead>
          <tr>
            <th style={{ width: 145 }}>{t("dateTimeText", { ns: ["main"] })}</th>
            <th>{t("nameText", { ns: ["main"] })}</th>
            <th>{t("phoneNumberText", { ns: ["main"] })}</th>
            <th>{t("typeText", { ns: ["main"] })}</th>
            <th style={{ width: 110 }}>{t("callTimeText", { ns: ["main"] })}</th>
            <th style={{ width: 110 }}>{t("statusText", { ns: ["main"] })}</th>
          </tr>
        </thead>
        <tbody>{resultOutput.map(renderResultOutputContent)}</tbody>
      </table>
    );
  };

  const renderReport = () => {
    if (!analysis && !resultOutput.length) return <Empty />;

    return (
      <Space direction="vertical">
        {renderAnalysis()}
        {analysis && resultOutput.length ? <Divider /> : ""}
        {renderResultOutput()}
      </Space>
    );
  };

  return (
    <Drawer
      title={t("analysisText", { ns: ["main"] }) || "Analysis"}
      placement="right"
      width="100%"
      onClose={handleCloseDrawer}
      open={Boolean(showDrawer)}
      className="analysis-drawer"
    >
      {loading ? <Loading message={t("fetchingDataText", { ns: ["main"] })} /> : renderReport()}
    </Drawer>
  );
};

export default ReportDrawer;
