import { FC, useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import OtpInput from "react-otp-input";
import { useTranslation } from "react-i18next";
import { Alert, Button, Col, Form, message, Result, Row, Typography } from "antd";

import useAuth from "../../hooks/useAuth";
import { URL } from "../../utils/constants";
import { setUser } from "../../utils/utility";
import { ILocation } from "../../interfaces/common";
import AuthService from "../../services/auth.service";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { ILoginResponseValues } from "../../interfaces/auth";

const Mfa: FC = () => {
  const currentUser = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location: ILocation = useLocation();
  const [messageApi, contextHolder] = message.useMessage();

  const { t } = useTranslation(["auth", "form", "main"]);
  useDocumentTitle(t("verifyMfa"));

  const [form] = Form.useForm();
  const mfaToken = searchParams.get("mfaToken");
  const redirect = searchParams.get("redirect");

  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);

  useEffect(() => {
    // if the user is already logged in, no need to do it again
    if (currentUser) {
      setRedirectToReferrer(true);
    }
  }, [currentUser]);

  if (redirectToReferrer) {
    let redirectUrl = null;
    if (typeof location.state == "string") {
      const state = JSON.parse(location.state);
      redirectUrl = state?.from;
    }

    return <Navigate to={redirectUrl || URL.DASHBOARD} replace />;
  }

  if (!mfaToken || mfaToken === "undefined") {
    return (
      <div className="login-page" id="loginPage">
        <Result
          status="error"
          title={t("invalidUrl", { ns: ["main"] })}
          subTitle={t("invalidUrlSubtitle", { ns: ["main"] })}
          extra={[
            <Link className="ant-btn ant-btn-primary" to={URL.LOGIN} key="buy">
              {t("goToLogin", { ns: ["auth"] })}
            </Link>,
          ]}
        />
      </div>
    );
  }

  const validateMfaToken = async (otpVal: string) => {
    if (!otpVal) {
      return false;
    } else if (otpVal.length !== 6) {
      return false;
    }

    return true;
  };

  const handleResendMfaToken = async () => {
    const response = await AuthService.resendMfaToken({ mfaProvider: 1, mfaToken });
    if (response.status === 200) {
      messageApi.open({
        type: "success",
        content: "OTP code has been resent",
      });
    } else {
      setLoading(false);
      setError(t("unknownErr", { ns: ["auth"] }) || "Unknown error occurred.");
    }
  };

  const onFinish = async () => {
    setError("");
    const isValid = await validateMfaToken(otp);
    if (!isValid) {
      setError(t("mfaRequired", { ns: ["auth"] }) || "The values should not be empty");
      return false;
    }

    setLoading(true);
    form.setFields([
      {
        name: "mfaCode",
        errors: [],
      },
    ]);

    AuthService.verifyMfaToken({ token: otp, mfaToken }).then(
      (response: { status: number; data: ILoginResponseValues }) => {
        if (response.status === 200) {
          if (response.data.tokenType) {
            let redirectUrl = "";
            if (response.data.tokenType === 2) {
              redirectUrl = redirect || URL.DASHBOARD;
              setUser(response.data);
            } else {
              redirectUrl = `${URL.MFA}?mfaToken=${response.data.token}`;
            }
            navigate(redirectUrl);
          } else {
            setLoading(false);
            setError(t("unknownErr", { ns: ["form"] }) || "Unknown error occurred.");
          }
        } else if (response.status === 400) {
          setLoading(false);
          setError(response.data ? `${response.data}` : t("invalidToken", { ns: ["auth"] }) || "Invalid Token.");
        } else {
          setLoading(false);
          setError(t("unknownErr", { ns: ["auth"] }) || "Unknown error occurred.");
        }
      },
      err => {
        const resMessage = err.response?.data?.message || err.message || err.toString();

        setError(resMessage);
        setLoading(false);
      }
    );
  };

  const renderForm = () => {
    return (
      <Form form={form} name="mfa_form" layout="vertical" onFinish={onFinish} autoComplete="off">
        {error && <Alert message={error} type="error" showIcon />}

        <Form.Item label={t("enterMfa", { ns: ["auth"] })} name="mfaCode" className="mfa-code-item">
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            inputStyle="ant-input"
            containerStyle="otp-inputs"
            renderSeparator={<span> - </span>}
            renderInput={props => <input {...props} />}
          />
        </Form.Item>

        <Form.Item className="form-actions">
          <Button type="primary" htmlType="submit" className="login-form-button" loading={loading} block>
            {loading ? t("pleaseWait", { ns: ["main"] }) : t("submitText", { ns: ["form"] })}
          </Button>
        </Form.Item>

        <Row style={{ marginTop: 14 }}>
          <Col span={12}>
            <Link to={URL.LOGIN}>{t("goToLogin", { ns: ["auth"] })}</Link>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Button className="link" type="link" onClick={handleResendMfaToken}>
              {t("resendMfa", { ns: ["auth"] })}?
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  return (
    <>
      {contextHolder}
      <div className="login-page mfa-page" id="loginPage">
        <div className="login-form">
          <div className="login-form-header">
            <Typography.Title>{t("verifyMfa", { ns: ["auth"] })}</Typography.Title>
          </div>
          {renderForm()}
        </div>
      </div>
    </>
  );
};

export default Mfa;
