import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import { useTranslation } from "react-i18next";

import { Alert, Button, Card, Col, Collapse, Row, Space } from "antd";
import { ArrowLeftOutlined, CaretRightOutlined } from "@ant-design/icons";

import Loading from "../../components/Loading";

import SurveyService from "../../services/survey.service";
import AudioService from "../../services/audio.service";
import { ISurvey, ISurveyForm, IQuestionnaire } from "../../interfaces/survey";

import { URL } from "../../utils/constants";
import { IPageProps } from "../../interfaces/common";
import { QuestionnaireTypeEnum } from "../../utils/enums";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { constructEmptyQuestionnaire, flattenArr, toTree, textToSpeech } from "../../utils/utility";

import "./form.scss";

const initialId = Date.now();

const sampleData: ISurveyForm = {
  name: "",
  remarks: "",
  questionnaires: [
    {
      tempId: initialId,
      title: "",
      content: "",
      type: 1,
      optionType: 2,
      dependentOption: null,
      parentQuestionId: null,
      optionList: [],
      questionnaires: [],
    },
  ],
};

interface IAudio {
  id: number;
  url: string;
}

const QuestionnaireForm: FC<IPageProps> = (props: IPageProps) => {
  const { id } = useParams();

  const { t, i18n } = useTranslation(["page", "form", "enum", "main"]);
  useDocumentTitle(t(id ? "editQuestionnaire" : "addQuestionnaire", { ns: ["page"] }) || props.pageMetaTitle);

  const questionnaireNameText = t("questionnaireNameText", { ns: ["main"] });
  const remarksText = t("remarksText", { ns: ["main"] });

  const navigate = useNavigate();

  const defaultFormData = {
    ...sampleData,
    questionnaires: flattenArr(sampleData.questionnaires || []),
  };

  const [isLoading, setIsLoading] = useState<boolean>(id ? true : false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState<ISurveyForm>(defaultFormData);
  const [audio, setAudio] = useState<IAudio[]>([]);

  useEffect(() => {
    if (id) {
      (async () => {
        await fetchData(Number(id));
      })();
    }
  }, [id]);

  const fetchData = async (qId: number) => {
    setIsLoading(true);
    const response = await SurveyService.getById(qId);
    if (response?.status === 200) {
      const surveyData = { ...response.data };
      delete surveyData.questionnaire;
      setFormData({
        ...surveyData,
        questionnaires: flattenArr([response.data.questionnaire]),
      });
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div className="questionnaire-form-page">
        <div className="app-container">
          <Loading message={t("fetchingDataText", { ns: ["main"] })} />
        </div>
      </div>
    );
  }

  const onFinish = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);
    if (formData.questionnaires?.length) {
      const surveyData = { ...formData };
      delete surveyData.id;
      delete surveyData.lastUpdatedAt;
      delete surveyData.questionnaires;
      const thisFormData: ISurvey = { ...surveyData, questionnaire: formData.questionnaires[0] };
      const questionnaires = toTree(formData.questionnaires, null, true);
      thisFormData.questionnaire = questionnaires[0];

      let response;
      if (id) {
        response = await SurveyService.update(Number(id), thisFormData);
      } else {
        response = await SurveyService.add(thisFormData);
      }
      if (response?.status === 200) {
        navigate(URL.QUESTIONNAIRE);
      } else {
        setError(response.data);
      }
    }
    setIsSubmitting(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const thisFormData = {
      ...formData,
      [event.target.name]: event.target.value,
    };

    setFormData(thisFormData);
  };

  const getQuestionChangeData = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let name = event.target.dataset["name"] as string;
    let value: unknown = Number(event.target.value);
    if (["title", "content"].includes(name)) {
      value = event.target.value;
    } else if (name.includes("optionList")) {
      const split = _.split(name, "_");
      const valArr: number[] = [];
      const currentChecked = (event.target as HTMLInputElement).checked;
      [...Array(Number(split[1]))].forEach((__, i) => {
        const thisIndex = i + 1;
        if (currentChecked) {
          valArr.push(thisIndex);
        } else {
          if (thisIndex !== Number(split[1])) {
            valArr.push(thisIndex);
          }
        }
      });
      value = valArr;
      name = split[0];
    }

    return {
      name,
      value,
    };
  };

  const updateChildQuestions = (currentQuestion: IQuestionnaire, options: number[], index = -1) => {
    const questionId = currentQuestion.id || currentQuestion.tempId;
    const thisFormData = { ...formData };

    const getChildIds = (data: IQuestionnaire[], pid: number) => {
      return data.reduce((ids: number[], e: IQuestionnaire) => {
        if (e.parentQuestionId == pid) {
          const qId = e.id || e.tempId;
          if (qId) {
            ids.push(qId);
            const childIds = getChildIds(data, qId);
            ids.push(...childIds);
          }
        }
        return ids;
      }, []);
    };

    if (thisFormData.questionnaires?.length && questionId) {
      if (!options.length) {
        const childIds = getChildIds(thisFormData.questionnaires, questionId);
        const newQuestions: IQuestionnaire[] = [];
        thisFormData.questionnaires.forEach(q => {
          const qId = q.id || q.tempId;
          if (qId && !childIds.includes(qId)) {
            newQuestions.push(q);
          }
        });

        thisFormData.questionnaires = newQuestions;
      } else {
        if (options.length > currentQuestion.optionList.length) {
          const newQuestion = constructEmptyQuestionnaire(questionId, options[options.length - 1]);
          thisFormData.questionnaires.push(newQuestion);
        } else {
          const thisQuestionnaires = formData.questionnaires || [];
          const removedQuestions = [...thisQuestionnaires].filter(
            q => q.dependentOption && q.dependentOption >= options.length + 1 && q.parentQuestionId === questionId
          );
          const removedQuestionIds = removedQuestions.map(q => {
            const qId = q.id || q.tempId;
            return qId;
          });
          const remainingQuestions = thisFormData.questionnaires.filter(q => {
            const qId = q.id || q.tempId;
            if (qId && !removedQuestionIds.includes(qId)) {
              return true;
            }
          });
          const childIds = getChildIds(remainingQuestions, questionId);
          const updatedCurrentQuestion = thisQuestionnaires.find(q => {
            const qId = q.id || q.tempId;
            if (qId === questionId) {
              return true;
            }
          });
          if (updatedCurrentQuestion) {
            const newQuestions: IQuestionnaire[] = [updatedCurrentQuestion];
            remainingQuestions.forEach(q => {
              const qId = q.id || q.tempId;
              if (qId && childIds.includes(qId)) {
                newQuestions.push(q);
              }
            });
            thisFormData.questionnaires = newQuestions;
          }
        }
      }
      setFormData(thisFormData);
    }
  };

  const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const qId = Number(event.target.dataset["id"]);
    const index = _.findIndex(formData.questionnaires, question => {
      return (question.id || question.tempId) === qId;
    });
    const nameVal = getQuestionChangeData(event);

    const thisFormData = { ...formData };

    if (thisFormData.questionnaires?.length) {
      const thisQuestionnaires = formData.questionnaires || [];
      const currentQuestion = [...thisQuestionnaires][index];
      if (nameVal.name === "optionType") {
        thisFormData.questionnaires[index] = {
          ...currentQuestion,
          optionList: [],
          optionType: nameVal.value as number,
        };
        setFormData(thisFormData);
        if (nameVal.value === 2) {
          updateChildQuestions(currentQuestion, []);
        }
      } else if (nameVal.name === "optionList") {
        const value = nameVal.value as number[];
        thisFormData.questionnaires[index] = {
          ...currentQuestion,
          optionList: value,
        };
        setFormData(thisFormData);
        updateChildQuestions(currentQuestion, value, index);
      } else {
        thisFormData.questionnaires[index] = {
          ...currentQuestion,
          [nameVal.name]: nameVal.value,
        };
        setFormData(thisFormData);
      }
    }
  };

  const handleAudioUpload = async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const files = (event.target as HTMLInputElement).files;

    if (files && files.length > 0) {
      const response = await AudioService.uploadAudio(files[0]);
      if (response.status === 200) {
        const qId = Number(event.target.dataset.id);
        const index = _.findIndex(formData.questionnaires, question => {
          return (question.id || question.tempId) === qId;
        });
        const thisAudio = [...audio].filter(a => a.id !== qId);
        thisAudio.push({
          id: qId,
          url: response.data.audioUrl,
        });
        setAudio(thisAudio);

        const nameVal = {
          name: "content",
          value: response.data.audioUrl,
        };
        const thisFormData = { ...formData };
        if (thisFormData.questionnaires?.length) {
          const thisQuestionnaires = formData.questionnaires || [];
          const currentQuestion = [...thisQuestionnaires][index];
          thisFormData.questionnaires[index] = {
            ...currentQuestion,
            [nameVal.name]: nameVal.value,
          };
          setFormData(thisFormData);
        }
      }
    }
  };

  const renderPlayer = (questionnaire: IQuestionnaire) => {
    const qId = questionnaire.id || questionnaire.tempId;
    const thisAudio = audio.find(a => a.id === qId);

    if (thisAudio?.url || questionnaire.content.includes("https://")) {
      return (
        <div style={{ paddingTop: 10 }}>
          <audio controls>
            <source src={thisAudio?.url || questionnaire.content} type="audio/mpeg" />
            Your browser does not support the html audio tag.
          </audio>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const renderSubQuestionnaire = (questionnaire: IQuestionnaire, index: number) => {
    const getMyColor = () => {
      const n = (Math.random() * 0xfffff * 1000000).toString(16);
      return "#" + n.slice(0, 6);
    };
    const title = questionnaire.dependentOption
      ? t("whenYouPressOptionText", { option: questionnaire.dependentOption, ns: ["main"] })
      : t("startText", { ns: ["main"] });
    const qId = questionnaire.id || questionnaire.tempId;
    const borderColor = getMyColor();

    return (
      <Collapse
        key={index}
        // defaultActiveKey={id ? `${id}` : `${initialId}`}
        className="question-collapse"
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        style={{ borderColor: borderColor }}
      >
        <Collapse.Panel key={qId || index} header={title} style={{ borderBottom: `1px solid ${borderColor}` }}>
          <div className="ant-form-item">
            <Row className="ant-form-item-row">
              <Col className="ant-form-item-control-input" span={24}>
                <input
                  required
                  type="text"
                  name={`question_${qId}`}
                  id={`question_${qId}`}
                  value={questionnaire.title}
                  className="ant-input"
                  onChange={handleQuestionChange}
                  data-name="title"
                  data-id={qId}
                  placeholder="Question"
                />
              </Col>
            </Row>
          </div>

          <div className="ant-form-item">
            <Row className="ant-form-item-row">
              <Col className="ant-form-item-label" span={24} style={{ paddingBottom: 0 }}>
                <label className="ant-form-item-required">{t("audioToPlayText", { ns: ["main"] })}</label>
              </Col>
              <Col className="ant-form-item-control-input" span={24}>
                {QuestionnaireTypeEnum.map(type => {
                  if (type.value !== 3) {
                    return (
                      <label key={type.value} className="ant-radio-wrapper question-radio">
                        <input
                          type="radio"
                          id={`question_type_${type.value}_${qId}`}
                          name={`question_type_${qId}`}
                          data-name="type"
                          value={type.value}
                          data-id={qId}
                          checked={type.value === questionnaire.type}
                          onChange={handleQuestionChange}
                        />
                        <span>{t(type.key, { ns: ["enum"] })}</span>
                      </label>
                    );
                  }
                })}
              </Col>

              <Col span={24} style={{ paddingTop: 5 }}>
                {questionnaire.type === 2 ? (
                  <>
                    <input
                      id={"audio_" + qId}
                      data-id={qId}
                      name="audio"
                      type="file"
                      onChange={handleAudioUpload}
                      accept="audio/mpeg3"
                    />
                    {renderPlayer(questionnaire)}
                  </>
                ) : (
                  <>
                    <div className="play-btn-container">
                      <Button
                        type="primary"
                        onClick={() =>
                          textToSpeech(questionnaire.content, i18n.language === "jp" ? "ja" : i18n.language)
                        }
                      >
                        {t("readText", { ns: ["main"] })}
                      </Button>
                    </div>
                    <textarea
                      name={"content_" + qId}
                      id={"content_" + qId}
                      data-name="content"
                      data-id={qId}
                      required
                      className="ant-input"
                      onChange={handleQuestionChange}
                      value={questionnaire.content}
                    ></textarea>
                  </>
                )}
              </Col>
            </Row>
          </div>

          <div className="ant-form-item" style={{ marginBottom: 0 }}>
            <Row className="ant-form-item-row">
              <Col className="ant-form-item-label" span={24}>
                <label htmlFor={"question_" + qId} className="ant-form-item-required" title="Question">
                  {questionnaire.type === 2
                    ? t("flowAfterVoicePlaybackText", { ns: ["main"] })
                    : t("readingText", { ns: ["main"] })}
                </label>
              </Col>
              <Col className="ant-form-item-control-input" span={24}>
                <label className="ant-radio-wrapper question-radio">
                  <input
                    type="radio"
                    id={"optionType_1_" + qId}
                    name={"optionType_" + qId}
                    data-name="optionType"
                    data-id={qId}
                    value={1}
                    checked={questionnaire.optionType === 1}
                    onChange={handleQuestionChange}
                  />
                  <span>{t("numberAnswerText", { ns: ["main"] })}</span>
                </label>
                <label className="ant-radio-wrapper question-radio">
                  <input
                    type="radio"
                    id={"optionType_1_" + qId}
                    name={"optionType_" + qId}
                    data-name="optionType"
                    data-id={qId}
                    value={2}
                    checked={questionnaire.optionType === 2}
                    onChange={handleQuestionChange}
                  />
                  <span>{t("endText", { ns: ["main"] })}</span>
                </label>
              </Col>
              {questionnaire.optionType === 1 && (
                <Col span={24} style={{ marginTop: 4 }}>
                  {[...Array(10)].map((__, i) => (
                    <label key={i} className="ant-radio-wrapper question-checkbox">
                      <input
                        type="checkbox"
                        id={`optionList_${i}_${qId}`}
                        name={`optionList_${qId}`}
                        data-name={`optionList_${i + 1}`}
                        data-id={qId}
                        value={i + 1}
                        checked={questionnaire.optionList.includes(i + 1)}
                        onChange={handleQuestionChange}
                        disabled={questionnaire.optionList.length < i}
                      />
                      <span>{i + 1}</span>
                    </label>
                  ))}
                </Col>
              )}
            </Row>
          </div>

          {questionnaire.optionType === 1 && questionnaire.optionList.length ? (
            <Space direction="vertical" size="middle" style={{ display: "flex", marginTop: "25px" }}>
              {questionnaire.questionnaires?.map(renderSubQuestionnaire)}
            </Space>
          ) : (
            <div></div>
          )}
        </Collapse.Panel>
      </Collapse>
    );
  };

  const renderQuestionnaire = () => {
    const tree = toTree(formData.questionnaires || []);
    return renderSubQuestionnaire(tree[0], -1);
  };

  return (
    <div className="questionnaire-form-page">
      <div className="app-container">
        <h1>
          <ArrowLeftOutlined onClick={() => navigate(URL.QUESTIONNAIRE)} className="btn-back" />
          {t(id ? "editQuestionnaire" : "addQuestionnaire", { ns: ["page"] })}
        </h1>
        {error && <Alert message={error} type="error" showIcon closable />}
        <form className="ant-form" onSubmit={onFinish}>
          <Space direction="vertical" size="middle" style={{ display: "flex", width: "100%" }}>
            <Card>
              <div className="ant-form-item">
                <Row className="ant-form-item-row">
                  <Col span={24} className="ant-form-item-label">
                    <label htmlFor="name" className="ant-form-item-required" title={questionnaireNameText}>
                      {questionnaireNameText}
                    </label>
                  </Col>
                  <Col span={24} className="ant-form-item-control-input">
                    <input
                      name="name"
                      className="ant-input"
                      type="text"
                      required
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row>
              </div>
              <div className="ant-form-item">
                <Row className="ant-form-item-row">
                  <Col span={24} className="ant-form-item-label">
                    <label htmlFor="name" className="ant-form-item-required" title={remarksText}>
                      {remarksText}
                    </label>
                  </Col>
                  <Col span={24} className="ant-form-item-control-input">
                    <textarea
                      name="remarks"
                      required
                      className="ant-input"
                      onChange={handleInputChange}
                      value={formData.remarks}
                    ></textarea>
                  </Col>
                </Row>
              </div>
              {renderQuestionnaire()}
            </Card>

            <div className="ant-form-item form-actions">
              <Button onClick={() => setFormData(defaultFormData)} disabled={isSubmitting}>
                {t("resetText", { ns: ["main"] })}
              </Button>
              <Button type="primary" htmlType="submit" loading={isSubmitting}>
                {isSubmitting ? t("submittingText", { ns: ["form"] }) : t("submitText", { ns: ["form"] })}
              </Button>
            </div>
          </Space>
        </form>
      </div>
    </div>
  );
};

export default QuestionnaireForm;
